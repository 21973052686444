import loadable from '@loadable/component';
import { allOnly, authorizedOnly } from '@socgress/lib/constants/route-rights';
import { userSupportRoutes } from '@socgress/lib/support';
import { authRoutes } from 'features/auth/routes';
import { dashboardRoutes } from 'features/dashboard';
import { developerRoutes } from 'features/developer';
import { softwareRoutes } from 'features/software';
import { customersRoutes } from 'features/customers';
import { menuRoutes } from './features/menu/routes';
const router = () => [
    {
        path: '/app',
        exact: true,
        rights: allOnly,
    },
    ...authRoutes,
    ...dashboardRoutes,
    ...developerRoutes,
    ...softwareRoutes,
    ...customersRoutes,
    ...menuRoutes,
    {
        path: '/support',
        component: loadable(() => import('features/common/pages/base-support-page')),
        rights: authorizedOnly,
        routes: userSupportRoutes,
    },
    {
        path: '/settings',
        rights: authorizedOnly,
        component: loadable(() => import('features/common/pages/settings-page')),
    },
];
export { router };
