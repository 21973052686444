import { rgba } from "polished";
const colors = {
    surfaceAttention: '#F58300',
    surfaceBackground: '#ECEEF0',
    surfaceBackgroundSecondary: '#FFF',
    surfaceCard: '#F7FAFC',
    surfaceBackgroundAlternate: rgba('#E6EAED', 0.5),
    surfaceBorder: '#D0D4D9',
    surfaceBrand: '#008DF2',
    surfaceDisabled: '#465A89',
    surfaceError: '#E05438',
    surfaceIcon: '#ADBFCD',
    surfaceLine: 'rgba(220, 222, 224, 0.50)',
    surfacePromo: '#FF6933',
    surfaceSuccess: '#1EBD46',
    surfaceModalTint: 'rgba(160, 160, 163, 0.80)',
    textAttention: '#F58300',
    textBrand: '#008DF2',
    textError: '#E05438',
    textMain: '#03061A',
    textOnBrand: '#FFFFFF',
    textPromo: '#FF6933',
    textSecondary: '#7E898F',
    textSuccess: '#1EBD46',
};
const backgrounds = {
    lightGrey: colors.surfaceBackgroundSecondary,
    checkbox: colors.surfaceBackgroundSecondary,
    input: colors.surfaceBackgroundSecondary,
    mobileButton: colors.surfaceBackgroundSecondary
};
const borders = {
    button: colors.surfaceBorder,
    checkbox: colors.surfaceBorder,
    whiteBlueButton: colors.surfaceBorder,
};
const defaultShadow = {
    S: `0px 1px 2px 0px rgba(5, 16, 61, 0.08)`,
    M: '0px 8px 32px 0px rgba(0, 0, 0, 0.08)',
    L: '0px 10px 30px rgba(0, 0, 0, 0.2)',
};
const defaultHeader = {
    bgColor: colors.surfaceBackground,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackground,
    menuButtonColor: colors.surfaceBackground,
    activeMenuButtonColor: colors.surfaceBackground,
    desktopMenuBg: colors.surfaceBackground,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: colors.surfaceBorder,
    backgroundColor: colors.surfaceBackgroundSecondary,
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackground });
export const grayRedTheme = {
    colors,
    backgrounds,
    borders,
    loginBackground: '#E6EAED',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: "1224px",
    },
};
export const dashboardTheme = Object.assign(Object.assign({}, grayRedTheme), { header: whiteHeader });
