import { createAction } from 'redux-act';
export const closeMenu = createAction('On close header menu');
export const openMenu = createAction('On open header menu');
export const menuInitialState = {
    isMenuOpen: false,
};
export const menuActions = (on) => {
    on(openMenu, (state) => (Object.assign(Object.assign({}, state), { isMenuOpen: true })));
    on(closeMenu, (state) => (Object.assign(Object.assign({}, state), { isMenuOpen: false })));
};
