import { validationI18n } from '@socgress/lib/validation';
import { authI18n } from 'features/auth/lib/i18n';
import { dashboardI18nResources } from 'features/dashboard';
import { developerResources } from 'features/developer';
import { customersResources } from 'features/customers';
import { softwareI18nResources } from 'features/software';
import { supportResources } from '@socgress/lib/support/i18n';
import common from './common.json';
import { settingsResources } from '@socgress/lib/settings/lib/i18n';

export default {
  auth: authI18n.ru,
  dashboard: dashboardI18nResources.ru,
  validation: validationI18n.ru,
  developer: developerResources.ru,
  software: softwareI18nResources.ru,
  customers: customersResources.ru,
  support: supportResources.ru,
  settings: settingsResources.ru,
  common,
};
