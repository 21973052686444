import React from 'react';
import Responsive from 'react-responsive';
const breakpoints = {
    desktop: '769px',
    tablet: '768px',
    mobile: '425px',
};
export const Desktop = props => (React.createElement(Responsive, Object.assign({}, props, { minWidth: breakpoints.desktop })));
export const Tablet = props => (React.createElement(Responsive, Object.assign({}, props, { maxWidth: breakpoints.tablet })));
export const Mobile = props => (React.createElement(Responsive, Object.assign({}, props, { maxWidth: breakpoints.mobile })));
