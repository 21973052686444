import axios from "axios";
import { fillEndpointTemplate } from "./fill-endpoint-template";
const createSimpleApi = (baseURL = null) => {
    return function createEndpoint(method, httpMethod = "GET") {
        return (params = null, config = {}) => axios
            .request(Object.assign({ url: fillEndpointTemplate(`/${process.env.API_PREFIX || 'api'}/${method}`, params), method: httpMethod, baseURL,
            params }, config))
            .then((response) => response.data);
    };
};
export { createSimpleApi };
