import React, { useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader/root';
import { setLocale } from 'yup';
import { renderRoutes } from 'react-router-config';
import RouterEnhancer from '@socgress/lib/router-enhancer';
import { useTranslation } from 'react-i18next';
import { AuthProvider } from '@socgress/lib/auth-context';
import { ConnectedModalSystem } from '@socgress/lib/modal-system';
import { EchoPrivateUserChannel } from '../lib/echo';
import { router } from '../router';
import { ConnectedToastsContainer } from '@socgress/lib/toasts';
import { botsApi } from '../lib/api';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from './common';
import { Tablet } from '@socgress/lib/media';
import { UserScope } from '@socgress/lib/auth-context/user-scope';
import { BottomBar } from './bottom-bar';
const BotsApplication = () => {
    const routes = useMemo(() => router(), []);
    const [t] = useTranslation('toasts');
    useEffect(() => {
        setLocale(t('validation:validation', { returnObjects: true }));
    }, []);
    return (React.createElement(AuthProvider, { routes: routes, getUser: botsApi.getUser }, ({ isHeaderVisible }) => (React.createElement(React.Fragment, null,
        isHeaderVisible && (React.createElement(Header, null)),
        React.createElement(EchoPrivateUserChannel, null),
        React.createElement(ConnectedToastsContainer, { t: t }),
        React.createElement(RouterEnhancer, { routes: routes },
            React.createElement(ConnectedModalSystem, null),
            renderRoutes(routes)),
        isHeaderVisible && (React.createElement(Tablet, null,
            React.createElement(UserScope, { type: "user" },
                React.createElement(BottomBar, null))))))));
};
export default hot(BotsApplication);
