import { createReducer } from 'redux-act';
import {
  createDialog,
  dialogLoaded,
  startDialogLoading,
  messagesLoaded,
  addMessageToDialog,
  updateMessage,
  removeMessage,
  updateTyping,
  changeDialogStatus,
  dialogUpdated,
  socialAccountDetached, lastDialogsLoaded, updateMessageIds, dialogOnListUpdated, dialogCreated,
  dialogPanelUpdated, dialogPanelRemoved, resetLastDialogs,
} from './actions';

const initialState = {
  isLoading: true,
  hasMoreMessages: true,

  newDialogs: {
    items: [],
  },

  id: null,
  offset: 0,
  limit: 15,

  count: 0,
  messageIds: [],
  messages: {
    count: 0,
    items: [],
  },

  user: null,
  typing: {
    name: null,
    isTyping: false,
    ts: null,
    dialogId: null,
  },
};

const dialogReducer = createReducer((on) => {
  on(createDialog, (state, payload) => ({
    ...state,
    id: payload.dialogId,
  }));

  on(resetLastDialogs, state => ({
    ...state,
    ...initialState,
  }))

  on(lastDialogsLoaded, (state, payload) => {
    const filterExistsDialogs = payload.dialogs.items.filter(
        (newDialog) => !state.newDialogs.items.find((dialog) => dialog.id === newDialog.id),
    );

    return {
      ...state,
      newDialogs: {
        items: [...state.newDialogs.items, ...filterExistsDialogs],
        count: payload.dialogs.count,
        offset: 0,
      },
    };
  });

  on(dialogLoaded, (state, payload) => {
    const { dialog } = payload;

    return {
      ...state,
      isLoading: false,
      ...dialog,
      offset: 0,
      messageIds: getMessageIds(dialog.messages.items),
      hasMoreMessages: dialog.messages.items.length === 15,
    };
  });

  on(dialogCreated, (state, payload) => {
    const dialogs = [payload.dialog, ...state.newDialogs.items];

    return {
      ...state,
      newDialogs: {
        ...state.newDialogs,
        count: dialogs.length,
        items: dialogs,
      }
    }
  })

  on(startDialogLoading, (state) => ({
    ...state,
    isLoading: true,
  }));

  on(messagesLoaded, (state, payload) => {
    const { messages, offset } = payload;

    return {
      ...state,
      isLoading: false,
      hasMoreMessages: messages.length > 0,
      offset,
      messages: {
        ...state.messages,
        items: [...state.messages.items, ...messages],
      },
      messageIds: [...state.messageIds, ...getMessageIds(messages)],
    };
  });

  on(updateMessageIds, (state, payload) => ({
    ...state,
    messageIds: [...state.messageIds, ...getMessageIds(payload.messages ? payload.messages : payload)]
  }))

  on(dialogOnListUpdated, (state, { dialog }) => ({
    ...state,
    newDialogs: {
      ...state.newDialogs,
      items: state.newDialogs.items.map(storedDialog => {
        return storedDialog.id !== dialog.id ? storedDialog : {
          ...storedDialog,
          ...dialog
        };
      }),
    }
  }));

  /**
   * Method is used for:
   *  - receiving socket updates
   *  - creating template message while sending
   */
  on(addMessageToDialog, (state, payload) => {
    const { dialogId, message } = payload;

    /* Avoid duplication when e.g. receive socket update */
    if (state.messageIds.indexOf(message.id) !== -1) {
      return state;
    }

    return {
      ...state,
      newDialogs: {
        ...state.newDialogs,
        items: state.newDialogs.items.map(dialog => {
          if (dialog.id !== dialogId) {
            return dialog;
          }

          return {
            ...dialog,
            status: 'answered',
            last_message: message,
          }
        })
      },
      messages: {
        ...state.messages,
        items: [message, ...state.messages.items]
      },
    };
  });

  on(removeMessage, (state, payload) => {
    const messageId = payload.message_id || payload.messageId;

    const filteredMessages = state.messages.items.filter(
      (message) => message.id !== messageId,
    );

    return {
      ...state,
      messages: {
        ...state.messages,
        items: filteredMessages,
      },
      messageIds: getMessageIds(filteredMessages),
    };
  });

  on(updateMessage, (state, payload) => ({
    ...state,
    messages: {
      ...state.count,
      items: updateMessageById(state.messages.items, payload),
    },
    messageIds: replaceMessageId(state.messageIds, payload),
  }));

  on(updateTyping, (state, payload) => ({
    ...state,
    typing: {
      ...state.typing,
      ...payload,
    },
  }));

  on(changeDialogStatus, (state, payload) => ({
    ...state,
    dialog: {
      ...state.dialog,
      status: payload.status,
    },
    newDialogs: {
      ...state.newDialogs,
      items: state.newDialogs.items.map(dialog => {
        if (dialog.id !== payload.dialogId) {
          return dialog;
        }

        return {
          ...dialog,
          status: payload.status
        };
      })
    }
  }));

  on(dialogUpdated, (state, payload) => ({
    ...state,
    ...payload.updatedDialog,
  }));

  on(socialAccountDetached, (state, payload) => ({
    ...state,
    user: {
      ...state.user,
      accounts: {
        ...state.user.accounts,
        [payload.network]: undefined,
      },
    },
  }));

  on(dialogPanelUpdated, (state, { panelId, values }) => ({
    ...state,
    panels: state.panels.map((panel) => ({
      ...panel,
      ...(panelId === panel.id ? values : {}),
    })),
  }));

  on(dialogPanelRemoved, (state, { panelId }) => ({
    ...state,
    panels: state.panels.filter(panel => panel.id !== panelId),
  }));
}, initialState);

function getMessageIds(messages) {
  return messages.map((message) => message.id);
}

function updateMessageById(messages, payload) {
  const { message: updatedMessage } = payload;
  const messageId = payload.message_id || payload.messageId;
  return messages.map((message) => {
    if (message.id === messageId) {
      return {
        ...message,
        ...updatedMessage,
      };
    }

    return message;
  });
}

function replaceMessageId(messageIds, payload) {
  const { messageId, message } = payload;

  if (messageId === message.id) {
    return messageIds;
  }

  const nextMessageIds = [...messageIds];
  const oldIdIndex = messageIds.indexOf(messageId);

  if (oldIdIndex !== -1) {
    nextMessageIds.splice(oldIdIndex, 1);
  }

  nextMessageIds.push(message.id);
  return nextMessageIds;
}

export { dialogReducer };
