import { useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
function useBodyScrollLock(isLocked) {
    const scrollableElement = useRef();
    useEffect(() => {
        if (isLocked) {
            disableBodyScroll(scrollableElement.current);
        }
        else {
            enableBodyScroll(scrollableElement.current);
        }
    }, [isLocked]);
    return scrollableElement;
}
export { useBodyScrollLock };
