export const getOnlyChangedValues = (values, initialValues) => {
    const changedValues = Object
        .entries(values)
        .reduce((acc, [key, value]) => {
        const hasChanged = initialValues[key] !== value;
        if (hasChanged) {
            acc[key] = value;
        }
        return acc;
    }, {});
    return [changedValues, Object.keys(changedValues)];
};
