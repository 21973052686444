import { useEffect, useState } from "react";
import { EchoRegistry } from "@socgress/lib/echo";
function useEcho(callback) {
    const [echo, setEcho] = useState(null);
    useEffect(() => {
        EchoRegistry.getEcho().then(setEcho);
    }, [echo]);
    useEffect(() => {
        if (!echo || !callback) {
            return;
        }
        return callback(echo);
    }, [echo, callback]);
}
export { useEcho };
