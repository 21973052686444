import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next/dist/es/useTranslation';
import { useSelector } from 'react-redux';
import { UserListMenu } from '@socgress/ui/widgets/link-list-menu';
function NavigationSidebar({ hashLinks }) {
    const { t } = useTranslation('developer');
    const { pathname, hash } = useSelector((state) => state.router.location);
    const categoryLinks = hashLinks
        .filter((hashLink) => hashLink.id)
        .map((hashLink) => ({
        to: { pathname, hash: hashLink.id },
        value: hashLink.name,
    }));
    return (React.createElement(React.Fragment, null, hashLinks && (React.createElement(UserListMenu, { title: t('navigation'), to: pathname, linkList: categoryLinks, activeLink: categoryLinks.find((categoryLink) => `#${categoryLink.to.hash}` === decodeURIComponent(hash)) }))));
}
NavigationSidebar.propTypes = {
    hashLinks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })),
};
NavigationSidebar.defaultProps = {
    hashLinks: [],
};
export { NavigationSidebar };
