import { dialogReducer } from './dialog-reducer';
import {
    createDialog,
    dialogLoaded,
    messagesLoaded,
    startDialogLoading,
    addMessageToDialog,
    updateMessage,
    removeMessage,
    updateTyping,
    changeDialogStatus,
    dialogUpdated,
    updateMessageIds,
    /* Async actions */
    sendMessage,
    loadDialog,
    getDialogMessages,
    deleteSocpanelMessage,
    editMessage,
    updateDialogStatus,
    sendTyping,

    dialogPanelReactivate,

    loadPanelDialog,
    getLastDialogs,

    /* Dialog list actions */
    lastDialogsLoaded,
    dialogAnswered,
    /* Async actions */
} from './actions';

export {
    sendMessage,
    loadDialog,
    loadPanelDialog,
    getDialogMessages,
    deleteSocpanelMessage,
    editMessage,
    updateDialogStatus,
    sendTyping,

    dialogUpdated,
    addMessageToDialog,
    updateMessageIds,

    dialogPanelReactivate,
};

export default dialogReducer;
