import React from "react";
import PropTypes from "prop-types";
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { TitleText, WhiteCardStyle } from '@socgress/ui';
import closeIconSvg from "./close-modal-icon.svg";
import { color } from '@socgress/ui/themes';
function ModalTemplate({ container, desktopWidth, tabletWidth, onClose, children, }) {
    const Container = container || ModalContainer;
    return (React.createElement(Container, { desktopWidth: desktopWidth, tabletWidth: tabletWidth },
        onClose && React.createElement(Close, { onClick: onClose }),
        children));
}
ModalTemplate.propTypes = {
    container: PropTypes.elementType,
    desktopWidth: PropTypes.number,
    tabletWidth: PropTypes.number,
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
};
ModalTemplate.defaultProps = {
    container: null,
    onClose: null,
    desktopWidth: 380,
    tabletWidth: 500,
};
export { ModalTemplate };
const ModalContainer = styled.div.attrs((props) => ({
    desktopWidth: `${props.desktopWidth}px`,
    tabletWidth: `${props.tabletWidth}px`,
})).withConfig({ displayName: "ModalContainer", componentId: "sc-j5m9uh" }) `
  width: ${prop("desktopWidth")};
  max-width: 100%;

  position: relative;
  border-radius: 20px;
  background-color: ${color('surfaceBackgroundSecondary')};

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    border-radius: 10px 10px 0 0;
  }
`;
ModalContainer.propTypes = {
    desktopWidth: PropTypes.number.isRequired,
    tabletWidth: PropTypes.number.isRequired,
};
const Close = styled.a.withConfig({ displayName: "Close", componentId: "sc-g81s94" }) `
  width: 40px;
  height: 40px;
  z-index: 1051;

  position: absolute;
  top: -15px;
  right: -15px;
  background: ${color('surfaceBackgroundSecondary')} url(${closeIconSvg}) no-repeat center center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
  }
`;
const BodyContainer = styled.div.withConfig({ displayName: "BodyContainer", componentId: "sc-xlaw0i" }) `
  overflow: auto;
`;
const ModalTitle = styled(TitleText).withConfig({ displayName: "ModalTitle", componentId: "sc-93kj8g" }) `
  margin: 26px 30px 16px;
`;
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-1kjwbue" }) `
  margin: 16px 30px 30px;
`;
const WhiteBodyContainer = styled.div.withConfig({ displayName: "WhiteBodyContainer", componentId: "sc-1csikwx" }) `
  @media(max-width: 768px) {
    padding: 10px;
    ${WhiteCardStyle};
    
    ${ifProp('marginTop', css `margin-top: ${prop('marginTop')}px;`)};
  }
`;
export { ModalContainer, ModalTitle, WhiteBodyContainer, BodyContainer, Body, Close };
