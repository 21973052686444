import { createSimpleApi, } from "./create-simple-api";
import { request } from './request';
const createEndpoint = createSimpleApi(process.env.API_BASE_URL);
export const panelAuthApi = {
    signIn: request("login", "POST"),
    signUp: request("register", "POST"),
    logout: createEndpoint("logout"),
    getUser: createEndpoint("user"),
    getPanel: createEndpoint("panel"),
    readNotification: createEndpoint('readNotification', 'POST'),
    //confirm: createEndpoint<any, Socpanel.User>('login/confirm', 'POST'),
    confirm: createEndpoint('login/confirm', 'POST'),
    resetPasswordVariants: createEndpoint('login/resetPasswordVariants', 'POST'),
    attemptPasswordReset: createEndpoint('login/attemptResetPassword', 'POST'),
    passwordReset: createEndpoint('login/resetPassword', 'POST'),
};
