import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from 'styled-tools';
import { preloadImage } from '@socgress/lib/helpers/preloadImage';
import { color } from '@socgress/ui/themes';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ie0nxx" }) `
  border-radius: 10px;
  background: ${color('surfaceBackgroundSecondary')};
  color: ${theme('colors.surfaceBackgroundSecondary')};
`;
export const Title = styled(RouterLink).withConfig({ displayName: "Title", componentId: "sc-s64qwy" }) `
  display: block;
  background: url(${(props) => props.icon.default}) no-repeat 15px center;
  background-size: 22px;
  padding: 15px 0 15px 55px;
  color: ${theme('colors.textMain')};
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;

  &:hover {
    background-image: url(${(props) => props.icon.active});
  }

  &:before {
    ${(props) => preloadImage(props.isActive ? props.icon.default : props.icon.active)};
  }

  ${(props) => props.isActive
    && css `
            background-image: url(${props.icon.active});
          `};
}
`;
export const LinkList = styled.div.withConfig({ displayName: "LinkList", componentId: "sc-1ah7isz" }) `
  padding: 12px 0;
`;
export const Link = styled(RouterLink).withConfig({ displayName: "Link", componentId: "sc-1x29a5q" }) `
  color: ${theme('colors.textSecondary')};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 11px 18px;
  display: block;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  position: relative;

  & + & {
    border-top: 1px solid ${color('surfaceLine')};
  }

  &:before {
    position: absolute;
    content: '';
    top: 20%;
    left: 0;
    width: 2px;
    height: 25px;
    background: ${color('surfaceBrand')};
    opacity: 0;
    transition: opacity 200ms ease;
    border-radius: 0 1px 1px 0;
  }

  &:hover {
    color: ${theme('colors.textMain')};
  }

  ${(props) => props.isActive
    && css `
            color: ${theme('colors.textMain')};
            
            &:before {
              opacity: 1;
            }
          `};
`;
