import React, { useEffect, useMemo, useState, } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled, { css, keyframes } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';
import { color } from '../themes';
import { Link } from 'react-router-dom';
import { useFirstRun, useOnClickOutside } from '@socgress/lib/hooks';
import { MenuGroup, MenuItem } from '@socgress/ui/header';
import { Content, NavigationLink, Title } from '@socgress/ui/header/mobile-menu/menu-item/styled';
import { StyledLink } from '@socgress/ui/header/mobile-menu/menu-item';
import { CopyIcon } from '@socgress/ui/copyable-text';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getPanelIcon } from '@socgress/lib/helpers/get-panel-icon';
import { Button } from '@socgress/ui/button';
import { useTranslation } from 'react-i18next';
const PopupMenu = ({ buttonContent: ButtonContent, buttonContainer: ButtonContainer, useButton, isVisibleOnHover = true, useText = false, links, onStateChange, menuContainer, style = {}, children, handleLinkClicked, customButtonContainer, buttonProps = {}, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [t] = useTranslation('common');
    const isFirstRun = useFirstRun();
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-end',
        modifiers: [
            {
                name: 'offset',
                options: { offset: [0, 10] },
            },
        ],
    });
    useOnClickOutside({
        current: popperElement,
    }, (e) => {
        if (!isOpen) {
            return;
        }
        if (referenceElement.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    });
    const onLinkClick = (link) => {
        if (link.onClick) {
            link.onClick();
            if (handleLinkClicked) {
                setTimeout(handleLinkClicked, 5);
            }
        }
        if (link.ignoreOnClick) {
            return;
        }
        setIsOpen(false);
    };
    const onOpenMenu = () => setIsOpen(prevState => !prevState);
    const grouppedLinks = useMemo(() => {
        const linkGroups = Array.isArray(links[0]) ? links : [links];
        return linkGroups.filter(linkGroup => linkGroup.length).map((linkGroup, i) => (React.createElement(MenuGroup, { key: i }, linkGroup.filter(link => typeof link.isVisible !== 'undefined' ? link.isVisible : true).map(link => {
            if (link.customRender) {
                return link.customRender;
            }
            if (!link.onClick && !link.to) {
                if (link.isCopyable) {
                    return (React.createElement(CopyToClipboard, { text: link.copyText || link.title, onCopy: link.onCopy },
                        React.createElement(TextContainer, { isCopyable: true },
                            React.createElement("div", null, link.title))));
                }
                return (React.createElement(TextContainer, null,
                    link.title,
                    link.isCopyable && (React.createElement(CopyIcon, null))));
            }
            if (!link.icon) {
                return (React.createElement(OldMenuItem, Object.assign({ key: link.title, as: !link.to ? 'div' : Link, isVisible: isOpen }, link, { onClick: !link.to ? () => onLinkClick(link) : null }), link.title));
            }
            return (React.createElement(MenuItem, Object.assign({ key: link.title, as: !link.to || link.as ? (link.as || 'div') : Link, isVisible: isOpen }, link, { href: link.to, onClick: !link.to ? () => onLinkClick(link) : null, linkProps: link.linkProps || {} }), link.title));
        }))));
    }, [links]);
    useEffect(() => {
        if (isFirstRun) {
            return;
        }
        if (isOpen && !isVisibleOnHover) {
            setIsOpen(false);
        }
        else if (isOpen && isVisibleOnHover && update) {
            update();
        }
        if (typeof onStateChange === 'function') {
            onStateChange(isOpen);
        }
    }, [isOpen, isVisibleOnHover]);
    const MenuContainer = menuContainer || React.Fragment;
    const CustomButtonContainer = customButtonContainer || 'div';
    return (React.createElement(React.Fragment, null,
        useButton ? (React.createElement(CustomButtonContainer, { ref: setReferenceElement, onClick: onOpenMenu }, children ? (typeof children === 'function' ? children(isOpen) : children) : (React.createElement(DotsContainer, { className: "popup-menu-container" },
            React.createElement("div", null))))) : (React.createElement(ButtonContainer, { isOpen: isOpen, ref: setReferenceElement, onClick: onOpenMenu, style: style },
            React.createElement(ButtonContent, { isOpen: isOpen }))),
        (isVisibleOnHover && isOpen) && ReactDOM.createPortal(React.createElement(MenuContainer, null,
            React.createElement(Menu, Object.assign({ isVisible: isOpen, ref: setPopperElement, style: styles.popper }, attributes.popper), grouppedLinks)), document.getElementById('popper-popup'))));
};
const MenuButton = styled(Button).attrs({
    buttonTheme: 'white',
    withBorder: true,
}).withConfig({ displayName: "MenuButton", componentId: "sc-8xv18c" }) `
  display: flex;
  align-items: center;
  // background-color: ${color('surfaceBackground')};
  position: relative;
  padding: 5px 10px;
  border-radius: 10px;
  
  @media(max-width: 768px) {
    padding: 0 10px;
  }
`;
const ArrowIcon = styled.div.withConfig({ displayName: "ArrowIcon", componentId: "sc-1doyvk6" }) `
  margin-left: 7px;
  width: 10px;
  height: 6px;
  mask: url('${require('../header/arrow.svg')}') no-repeat;
  mask-size: contain;
  background-color: ${color('surfaceBrand')};
`;
const Dots = styled.button.withConfig({ displayName: "Dots", componentId: "sc-1hr1lcd" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: 0;

  position: relative;
  transition: transform 200ms ease;
  width: 20px;
  height: 20px;
  padding: 10px;

  mask: url('${getPanelIcon('settings', require('@socgress/ui/header/icons/imgs/settings.svg'))}');
  mask-size: contain;
  background-color: ${color('surfaceIcon')};

  &:hover {
    transform: rotate(180deg);
  }

  &:before {
    left: -7px;
  }

  &:after {
    right: -7px;
  }
`;
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1pxgowc" }) `
  cursor: pointer;

  min-height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 200ms ease;
  padding: 5px 10px;

  ${ifProp('isOpen', css `
            z-index: 6;

            ${Dots} {
              &,
              &:before,
              &:after {
                background-color: ${color('surfaceBrand')};
              }
            }
          `)};

  &:hover ${Dots} {
    &,
    &:before,
    &:after {
      background-color: ${color('surfaceBrand')};
    }
  }
`;
PopupMenu.defaultProps = {
    buttonContent: Dots,
    buttonContainer: Container,
};
export { PopupMenu, Container };
const zoomIn = keyframes `
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const TextContainer = styled.p.withConfig({ displayName: "TextContainer", componentId: "sc-16l2qw1" }) `
  & > div {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 25px;
  }

  ${ifProp('isCopyable', css `
    cursor: pointer;

    & > div {
      position: relative;

      &:after {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;

        content: '';
        width: 15px;
        height: 15px;
        mask: url('${require('@socgress/ui/static/copy.svg')}');
        mask-size: contain;
        mask-repeat: no-repeat;
        margin: auto 0 auto auto;
        transition: background-color 200ms ease;

        background-color: ${color('surfaceIcon')};
      }
    }

    &:hover {
      & > div:after {
        background-color: ${color('surfaceBrand')};
      }
    }
  `)};

  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: ${color('textSecondary')};

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: ${color('textMain')};
    margin-top: 5px;
  }

  ${CopyIcon} {
    position: absolute;
    right: 0;
    top: 50%;
  }

  & + & {
    margin-top: 15px;
  }
`;
const Menu = styled.div.withConfig({ displayName: "Menu", componentId: "sc-o3itkg" }) `
  z-index: 99999;
  animation: ${zoomIn} 250ms ease-in;

  border: 1px solid ${color('surfaceBorder')};
  background-color: ${color('surfaceBackground')};
  border-radius: 10px;

  opacity: ${ifProp('isVisible', 1, 0)};
  visibility: ${ifProp('isVisible', 'visible', 'hidden')};
  box-shadow: ${theme('shadow.M')};

  transition: opacity 0.15s, visibility 0.15s, box-shadow 0.15s;
  display: flex;
  flex-direction: column;

  ${NavigationLink} {
    cursor: pointer;
    margin: 0 !important;
      
    align-items: center;
  }

  ${Content} {
    padding-right: 0;
    
    &:after {
      content: unset;
    }
  }

  ${StyledLink} {
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 10px;

    background: none;
      
    ${Title} {
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        font-weight: 600;

        color: ${color('textMain')};
    }
    
    &:hover {
      background-color: ${color('surfaceBackgroundAlternate')};
    }
    
    @media(max-width: 768px) {
      background: none;
      
      &::after {
        left: 0;
      }
    }
  }
  
  ${StyledLink} + ${StyledLink} {
    margin-top: 2px;
  }

  ${MenuGroup} {
      background: transparent;
    
    &:not(:last-child) {
      ${StyledLink}:last-child {
        margin-bottom: 5px;
      }
    }
    
    @media(max-width: 768px) {
      margin: 0;
        
      ${StyledLink} {
          padding: 16px 18px;
      }
      
      &:not(:first-child) ${StyledLink}:first-child {
        padding-top: 0;
      }
    }
  }
`;
const OldMenuItem = styled(Link).withConfig({ displayName: "OldMenuItem", componentId: "sc-1ex9gq5" }) `
  transition: color 200ms ease;
  color: ${prop('color', color('textMain'))};
  text-align: left;
  padding: 15px 0;

  &:hover {
    color: ${color('textMain')};
  }

  &:not(:first-child) {
    margin-top: 15px;
  }
`;
const DotsIcon = styled.div.withConfig({ displayName: "DotsIcon", componentId: "sc-f6ybuh" }) `
  width: 24px;
  height: 24px;
  mask: url(${require('./dots.svg')}) no-repeat;
  mask-size: contain;
  
  background-color: ${color('surfaceBrand')};
`;
const DotsContainer = styled.div.withConfig({ displayName: "DotsContainer", componentId: "sc-rackl4" }) `
  padding: 4px 8px;
    
  cursor: pointer;

  border-radius: 10px;
  background: ${p => p.theme.isDark ? color('surfaceBackground') : color('surfaceBackgroundSecondary')};

  div {
    width: 20px;
    height: 20px;

    mask: url("${require('./dots-v2.svg')}") no-repeat;
    mask-size: contain;

    background: ${color('textSecondary')};
  }
`;
