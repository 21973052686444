const commonModals = {
    login: () => import("./authorization/login-modal"),
    register: () => import("./authorization/sign-up-modal"),
    socpanelSupport: () => import('./socpanel-support'),
    socpanelSupportEnabled: () => import('./socpanel-support-enabled'),
    faq: () => import('./faq'),
    captcha: () => import('./captcha-modal'),
    modalSelect: () => import('./modal-select'),
};
export { commonModals };
