import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as store from './store';
import makeToast, { Toaster } from 'react-hot-toast';
import { ToastIcon } from '@socgress/lib/toasts/styled';
import { useTheme } from 'styled-components';
const iconByType = {
    error: {
        icon: require('./static/error.svg'),
        color: 'surfaceError'
    },
    success: {
        icon: require('./static/success.svg'),
        color: 'surfaceSuccess'
    },
};
const defaultToastOptions = {
    position: 'bottom-center',
    type: 'info',
    duration: 2000,
    draggablePercent: 25,
    style: {
        maxWidth: '450px',
    }
};
function ConnectedToastsContainer({ t }) {
    const dispatch = useDispatch();
    const [showed, setShowed] = useState([]);
    const toasts = useSelector((state) => state.toasts);
    const theme = useTheme();
    // Call react-toastify when `toasts` update
    useEffect(() => {
        const toastsToShow = Object.keys(toasts).filter((id) => !showed.includes(id));
        if (toastsToShow.length === 0) {
            return;
        }
        setShowed((showedIds) => [...showedIds, ...toastsToShow]);
        toastsToShow.forEach((toastId) => {
            const toast = toasts[toastId];
            const { options } = toast;
            const combinedOptions = Object.assign(Object.assign({}, defaultToastOptions), options);
            if (options.type === 'promise') {
                makeToast.promise(toast.content, combinedOptions, {
                    loading: Object.assign(Object.assign({}, defaultToastOptions), { type: 'loading' }),
                    success: Object.assign(Object.assign({}, combinedOptions), { icon: (React.createElement(ToastIcon, { icon: iconByType.success.icon, color: theme.colors.surfaceSuccess })) }),
                    error: Object.assign(Object.assign({}, combinedOptions), { icon: (React.createElement(ToastIcon, { icon: iconByType.error.icon, color: theme.colors.surfaceError })) }),
                }).then(options.then).catch(options.catch);
                return;
            }
            makeToast((typeof toast.content === 'function'
                ? toast.content(t)
                : toast.content), Object.assign(Object.assign({}, combinedOptions), { icon: combinedOptions.type && combinedOptions.type in iconByType ? (React.createElement(ToastIcon, { icon: iconByType[combinedOptions.type].icon, color: theme.colors[iconByType[combinedOptions.type].color] })) : undefined, onClose: () => {
                    if (typeof options.onClose === 'function') {
                        options.onClose();
                    }
                    dispatch(store.removeToast({ toastId }));
                } }));
        });
    }, [toasts, showed]);
    return ReactDOM.createPortal(React.createElement(Toaster, { containerStyle: {
            bottom: 70,
            zIndex: 999999999999
        }, toastOptions: {
            className: 'hot_toast'
        } }), document.getElementById('popper-popup'));
}
export { ConnectedToastsContainer };
