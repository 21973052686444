import { rgba } from 'polished';
const colors = {
    surfaceAttention: '#F58300',
    surfaceBackground: '#EFF0FA',
    surfaceBackgroundSecondary: '#FAFAFF',
    surfaceBorder: '#D1D2E0',
    surfaceBrand: '#008DF2',
    surfaceCard: '#FFFFFF',
    surfaceError: '#E05438',
    surfaceLine: 'rgba(209, 210, 224, 0.50)',
    surfaceModalTint: 'rgba(156, 157, 163, 0.80)',
    surfacePromo: '#FF6933',
    surfaceSuccess: '#1EBD46',
    textAttention: '#F58300',
    textBrand: '#008DF2',
    textError: '#E05438',
    textMain: '#03061A',
    textOnBrand: '#FFFFFF',
    textPromo: '#FF6933',
    textSecondary: '#5D5F7A',
    textSuccess: '#1EBD46',
    surfaceBackgroundAlternate: rgba('#E6EAED', 0.5),
    surfaceDisabled: '#465A89',
    surfaceIcon: '#ADBFCD',
};
const defaultShadow = {
    S: `0px 1px 2px 0px rgba(4, 4, 51, 0.12)`,
    M: '0px 8px 32px 0px rgba(4, 4, 10, 0.08)',
    L: '0px 10px 30px rgba(0, 68, 133, 0.15)',
};
const backgrounds = {
    lightGrey: '#EFF4FA',
    checkbox: '#fafcff',
    input: '#fff',
    mobileButton: 'linear-gradient(to right, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.04) 100%)'
};
const borders = {
    button: colors.surfaceBorder,
    checkbox: '#cedef2',
    whiteBlueButton: '#E1EDFA',
};
const defaultHeader = {
    bgColor: colors.surfaceBackgroundSecondary,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackgroundSecondary,
    menuButtonColor: colors.surfaceBackgroundSecondary,
    activeMenuButtonColor: colors.surfaceBackgroundSecondary,
    desktopMenuBg: colors.surfaceBackgroundSecondary,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: '#DBE7F2',
    backgroundColor: '#fff',
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackgroundSecondary });
export const lightRedTheme = {
    colors,
    backgrounds,
    borders,
    loginBackground: '#fff',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: '1224px',
    },
};
