import { useDispatch, useSelector } from 'react-redux';
import { useEcho } from '@socgress/lib/hooks/useEcho';
import { updateUser } from '@socgress/lib/store/actions/auth';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useIsWindowActive } from '@socgress/lib/hooks/use-is-window-active';
import { addNotification } from '@socgress/lib/notifications/store';
function EchoPrivateUserChannel() {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const isWindowActive = useIsWindowActive();
    useEffect(() => {
        if (user) {
            Sentry.configureScope((scope) => {
                scope.setUser({
                    id: user.id,
                    login: user.login,
                });
            });
        }
    }, [user]);
    useEcho((echo) => {
        if (!user) {
            return;
        }
        echo
            .private(`PanelUser.${user.id}`)
            .listen('PanelUserUpdated', (newUser) => {
            if (!isWindowActive) {
                return;
            }
            dispatch(updateUser(newUser));
        })
            .notification(notification => dispatch(addNotification(Object.assign(Object.assign({}, notification), { unread: true, created_at: Math.floor(Date.now() / 1000), data: notification }))));
        return () => echo.leave(`PanelUser.${user.id}`);
    });
    return null;
}
export { EchoPrivateUserChannel };
