class ReducerRegistry {
    constructor() {
        this._emitChange = null;
        this._reducers = {};
    }
    getReducers() {
        return Object.assign({}, this._reducers);
    }
    register(name, reducer) {
        this._reducers = Object.assign(Object.assign({}, this._reducers), { [name]: reducer });
        if (this._emitChange) {
            this._emitChange(this.getReducers());
        }
    }
    setChangeListener(listener) {
        this._emitChange = listener;
    }
}
const reducerRegistry = new ReducerRegistry();
export { reducerRegistry };
