import { Container, Content } from '@socgress/lib/internal-page/page-template';
import { createGlobalStyle } from 'styled-components';
import { TableContainer, TableHeader, TableHeaderCell, TableRow } from '@socgress/lib/socgress-table/base-table/styled';
import { color } from '@socgress/ui/themes/color';
import { BigText, MainText, MediumText, SmallText, TitleText, UltraSmallText } from '@socgress/ui';
import { theme } from 'styled-tools';
import { CopyIcon } from '@socgress/ui/copyable-text';
import { InputBase } from '@socgress/ui/inputs/input/input-base';
import { ButtonComponent } from '@socgress/ui/button';
export const BotsGlobalStyle = createGlobalStyle `
  body {
    font-family: Inter, "Roboto", Helvetica, sans-serif;
  }

  ${CopyIcon} {
    mask-image: url("${require('features/dashboard/organisms/services-table/static/copy.svg')}");
    background-color: ${color('textMain')};

    width: 20px;
    height: 20px;
  }

  ${InputBase} {
    background: ${color('surfaceBackground')};
  }

  ${ButtonComponent} {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  ${Content} {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;

    @media(min-width: 769px) {
      padding: 0 40px;
    }
  }

  .table-row-container:not(:last-child) {
    && {
      border-bottom: 1px solid ${color('surfaceBorder')};
    }
  }

  ${MediumText}, ${MainText} {
    &&& {
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  ${TitleText} {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 650;
    line-height: 24px;
  }

  ${BigText} {
    &&& {
      font-family: Inter, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }

  ${SmallText} {
    &&& {
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 550;
      line-height: 16px;
    }
  }

  ${UltraSmallText} {
    font-family: Inter, sans-serif;
  }

  .bold {
    &&& {
      font-weight: 600;
    }
  }

  ${TableContainer} {
    border-radius: 20px;
    background: ${color('surfaceCard')};
    border: 1px solid ${color('surfaceBorder')}
  }

  ${TableRow} {
    padding: 0 24px;
  }

  ${TableHeader} {
    background: transparent;
    border-bottom: 1px solid ${color('surfaceBorder')};
  }

  ${TableHeaderCell} {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    font-weight: 600;

    color: ${color('textMain')};
  }

  .hot_toast {
    padding: 16px 20px;

    border: 1px solid ${color('surfaceBorder')};
    border-radius: 10px;
    box-shadow: ${theme('shadow.M')};
    background: ${color('surfaceBackground')};

    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    gap: 10px;
    align-items: center;

    & > :last-child {
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    ${Container} {
      margin-top: 0;
    }
  }
`;
