import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { WhiteCard } from '@socgress/ui';
import { zord } from '@socgress/lib/zord';
import { BottomLink } from './bottom-link';
import { Container as PageContainer } from '@socgress/lib/internal-page/page-template';
import { useSelector } from 'react-redux';
import { color } from '@socgress/ui/themes';
export const BottomBar = () => {
    const user = useSelector(state => state.auth.user);
    return (React.createElement(Container, null,
        React.createElement(GlobalStyle, null),
        React.createElement(BottomLink, { icon: require('./static/home.svg'), to: '/dashboard', centerText: true }, "Home"),
        React.createElement(BottomLink, { icon: require('./static/payouts.svg'), to: '/payouts/all', centerText: true, counter: user ? user.withdrawals_initial_count : 0, isActive: (_, location) => location.pathname.includes('/payouts/') }, "Payouts"),
        React.createElement(BottomLink, { icon: require('./static/support.svg'), to: '/support/dialog', centerText: true }, "Support"),
        React.createElement(BottomLink, { icon: require('./static/menu.svg'), centerText: true, to: "/menu" }, "Menu")));
};
const Container = styled(WhiteCard).withConfig({ displayName: "Container", componentId: "sc-1awy5kt" }) `
  position: fixed;
  bottom: 0;
  padding: 0 8px;
  width: 100%;

  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${zord(4)};

  border-radius: 0;

  z-index: 2;

  background: ${color('surfaceBackground')};
  box-shadow: none;
`;
const GlobalStyle = createGlobalStyle `
  ${PageContainer} {
    padding-bottom: 50px;
  }
`;
