import { parseToHsl, toColorString } from 'polished';
export const isColorSafe = color => {
    try {
        toColorString(parseToHsl(color));
    }
    catch (e) {
        return false;
    }
    return true;
};
