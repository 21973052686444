const formatNumberDecimals = (number = 0, decimals = 2, fractionLen = 3, replacement = "$&  ") => {
  const re = `\\d(?=(\\d{${fractionLen}})+${decimals > 0 ? "\\." : "$"})`;

  let finalNumber = Number(number);

  if (decimals) {
    finalNumber = finalNumber.toFixed(decimals)
  }

  return finalNumber.toString().replace(new RegExp(re, "g"), replacement);
};

/**
 * @param {integer} number: number
 * @param {integer} decimals: length of decimal
 * @param {integer} fractionLen: length of sections
 */
const formatNumber = (number = 0, decimals = 4, fractionLen = 3) => {
  return formatNumberDecimals(number, decimals, fractionLen, "$& ")
    .replace(/(\.\d+?)0{1,4}$/, '$1')
    .replace(/\.(0{1,4})?$/, '');
};

const formatNumberV2 = (number = 0, decimals = 4, fractionLen = 3) => {
  return formatNumberDecimals(number, decimals, fractionLen, "$& ")
      .replace(/(\.\d+?)0{1,4}$/, '$1')
      .replace(/\.(0{1,4})?$/, '');
};

const formatPrice = (price) => formatNumber(price).split(" ").join("  "); // join with &#8202;

const formatNumberAbbr = (n, decimals = 0, min = null, useSpacer = false) => {
  if (isNaN(n)) {
    return 0;
  }

  decimals = Math.pow(10, decimals);

  let c;
  const d = [
    {
      n: 1000,
      m: 1,
      s: ''
    },
    {
      n: 1000000,
      m: 1000,
      s: 'K'
    },
    {
      n: 1000000000,
      m: 1000000,
      s: 'M'
    },
    {
      n: 100000000000 ,
      m: 1000000000,
      s: 'B'
    }
  ];

  for (let i = 0; i < d.length; i++) {
    c = d[i];

    if (n < c.n) {
      break;
    }
  }

  if (min && (n < min)) {
    decimals = 1;
  }

  return (Math.floor(n / (c.m / decimals)) / decimals) + (useSpacer ? ' ' : '') + c.s;
};

export { formatNumber, formatNumberV2, formatNumberAbbr, formatNumberDecimals, formatPrice };
