var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { ifNotProp, ifProp, prop } from 'styled-tools';
import { color } from '@socgress/ui/themes';
const Checkbox = styled.div.withConfig({ displayName: "Checkbox", componentId: "sc-fl8cp5" }) `
  min-height: 16px;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 20px;
  }
`;
const CheckboxLabel = styled.label.withConfig({ displayName: "CheckboxLabel", componentId: "sc-hobqz3" }) `
  color: ${ifProp("isTextMain", color('textMain'), color('textSecondary'))};
  font-weight: 500;
  font-size: ${ifProp("isTextMain", "14px", "16px")};
  line-height: 24px;
  padding-left: ${ifNotProp('hasLabel', '24px', '34px')};
  position: relative;
  cursor: pointer;
    
  ${ifNotProp('hasLabel', css `
      height: 24px;
  `)};

  &::before {
    content: "";
    border-radius: ${prop('borderRadius', 3)}px;
    width: 24px;
    height: 24px;
    border: 1px solid ${color('surfaceBorder')};
    background-color: ${color('surfaceBackground')};
    position: absolute;
    left: 0;
    top: 0;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    
    ${prop('checkStyle')};
  }

  &::after {
    content: "";
    width: 24px;
    height: 24px;
    mask: url(${require('./static/checkbox.svg')}) no-repeat center/auto 7px;
    background-color: ${p => color('surfaceBackground')(p) || '#fff'};
    position: absolute;
    top: 0;
    left: 0;
    ${prop('checkStyle')};
  }
`;
CheckboxLabel.propTypes = {
    isTextMain: PropTypes.bool,
};
CheckboxLabel.defaultProps = {
    isTextMain: false,
};
const CheckboxInput = styled.input.withConfig({ displayName: "CheckboxInput", componentId: "sc-18aph08" }) `
  position: absolute;
  left: -9999px;

  &:checked {
    & + ${CheckboxLabel} {
      &::before {
        background-color: #12c78c;
        border-color: #12c78c;
      }
    }

    &[disabled] + ${CheckboxLabel} {
      &::before {
        border: 1px solid #AFC0D5;
        background-color: #fafcff;
      }
    }
  }

  &:not(:checked) {
    &[disabled] + ${CheckboxLabel} {
      &::before {
        border: 1px solid #AFC0D5;
        background-color: #fafcff;
      }

      //&::after {
      //  background-color: #fafcff;
      //}
    }
  }
`;
const UICheckbox = (_a) => {
    var { id, className, label, state, isTextMain, checkStyle, labelContainer = undefined, borderRadius = 3, labelStyle = {} } = _a, props = __rest(_a, ["id", "className", "label", "state", "isTextMain", "checkStyle", "labelContainer", "borderRadius", "labelStyle"]);
    return (React.createElement(Checkbox, { className: className },
        React.createElement(CheckboxInput, Object.assign({}, props, { type: "checkbox", id: id })),
        React.createElement(CheckboxLabel, { as: labelContainer, htmlFor: id, state: state, isTextMain: isTextMain, borderRadius: borderRadius, checkStyle: checkStyle, hasLabel: !!label, style: labelStyle }, label)));
};
UICheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.node.isRequired,
    state: PropTypes.oneOf(["error", "info", "warning"]),
    isTextMain: PropTypes.bool,
};
UICheckbox.defaultProps = {
    className: null,
    isTextMain: false,
    state: null,
};
export { UICheckbox, CheckboxLabel };
