import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ifProp, prop } from 'styled-tools';
import { color } from '@socgress/ui/themes';
import { UltraSmallText, ultraSmallTextStyle } from '@socgress/ui';
export const BottomLink = ({ children: name, isHighlighted, centerText, icon, isActive, counter, to, }) => {
    return (React.createElement(Container, { isHighlighted: isHighlighted, counter: !isHighlighted && counter, to: to, isActive: isActive, centerText: centerText },
        React.createElement(Icon, { icon: icon }),
        React.createElement(UltraSmallText, null, name)));
};
const Icon = styled.div.withConfig({ displayName: "Icon", componentId: "sc-1dpp4gv" }) `
  width: 20px;
  height: 20px;
  mask: url(${prop('icon')}) no-repeat;
  mask-size: contain;

  transition: 200ms ease-in-out background-color;

  background: ${color('textSecondary')};
`;
const Container = styled(NavLink).withConfig({ displayName: "Container", componentId: "sc-1slrbzm" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 12px 0;

  border-radius: 10px;

  ${UltraSmallText} {
    transition: 200ms ease-in-out color;
  }

  &.active {
    ${UltraSmallText} {
      color: ${color('textMain')};
    }

    ${Icon} {
      background-color: ${color('textMain')};
    }
  }

  ${ifProp('isHighlighted', css `
    border-radius: 10px;
    padding: 7px 12px;
    background: ${color('surfaceBrand')};

    ${UltraSmallText} {
      color: ${color('textOnBrand')} !important;
    }

    ${Icon} {
      background-color: ${color('textOnBrand')} !important;
    }
  `)};

  ${ifProp('centerText', css `
    text-align: center;
  `)};

  ${ifProp('counter', css `
    position: relative;

    &:after {
      display: block;
      position: absolute;
      right: 15px;
      top: 0;
      content: '${prop('counter')}';
      border-radius: 16px;
      padding: 2px 7px;

      ${ultraSmallTextStyle};
      color: ${color('textOnBrand')};

      background: ${color('surfaceBrand')};
    }
  `)}

  & > :not(:first-child) {
    margin-top: 4px;
  }
`;
