import loadable from '@loadable/component';
import { authorizedOnly } from '@socgress/lib/constants/route-rights';
import { reducerRegistry } from '@socgress/lib/store/reducer-registry';
export const customersRoutes = [
    {
        path: '/customers',
        component: loadable(() => import('./pages/performers-page')),
        rights: authorizedOnly,
        preloadData({ dispatch }) {
            return import('./store').then(store => {
                reducerRegistry.register('customers', store.default);
                return Promise.all([
                    dispatch(store.loadCustomers()),
                ]);
            });
        },
    },
];
