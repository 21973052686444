var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import styled from "styled-components";
function ModalAdapter(_a) {
    var { className } = _a, props = __rest(_a, ["className"]);
    const contentClassName = `${className}__content`;
    const overlayClassName = `${className}__overlay`;
    useEffect(() => {
        Modal.setAppElement(document.getElementById("app"));
    }, []);
    return (React.createElement(Modal, Object.assign({ portalClassName: className, className: contentClassName, overlayClassName: overlayClassName }, props)));
}
ModalAdapter.propTypes = {
    className: PropTypes.string.isRequired,
};
export const ModalWrapper = styled(ModalAdapter).withConfig({ displayName: "ModalWrapper", componentId: "sc-2eauu6" }) `
  &__overlay {
    z-index: 1040;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    transition: opacity 0.4s;
    opacity: 0;
    
    &.ReactModal__Overlay--after-open {
      opacity: 1;
      //overflow: hidden;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }
`;
ModalWrapper.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    closeTimeoutMS: PropTypes.number,
};
ModalWrapper.defaultProps = {
    isOpen: false,
    onRequestClose: () => { },
    closeTimeoutMS: 0,
};
