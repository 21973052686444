import styled, { css } from "styled-components";
import { color } from "./themes";
export const mainTextStyle = css `
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  white-space: pre-wrap;
  
  color: ${color("textMain")};

  margin: 0;
`;
export const MainText = styled.p.withConfig({ displayName: "MainText", componentId: "sc-hdnz0y" }) `
  ${mainTextStyle};
`;
