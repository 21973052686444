import React from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { commonMedia } from "../common-media";
import { color } from '@socgress/ui/themes';
import { prop } from 'styled-tools';
export const ToastIcon = styled.div.withConfig({ displayName: "ToastIcon", componentId: "sc-ik4wm" }) `
  min-width: 20px;
  width: 20px;
  height: 20px;
  mask: url('${prop('icon')}');
  background-color: ${prop('color')};
`;
export const StyledToasts = styled(ToastContainer).withConfig({ displayName: "StyledToasts", componentId: "sc-1g2h0io" }) `
  .soc-toast {
    ${commonMedia.lessThan("tablet") `
      max-width: calc(100vw - 30px);
      margin: 15px;
    `};
  }

  .Toastify__toast {
    font-family: inherit;
    position: relative;
    border-radius: 5px;
    font-size: 14px;
    line-height: 19px;
    padding: 14px 0 14px 19px;

    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);

    color: ${color('textMain')};

    &--info,
    &--warning,
    &--error,
    &--blog,
    &--success {
      background-color: ${color('surfaceBackgroundSecondary')};
    }
  }

  .Toastify__toast--pack-of-day {
    padding: 0;
  }

  .Toastify__close-button {
    position: absolute;
    top: 9px;
    right: 9px;

    width: 19px;
    height: 19px;
    border-radius: 50%;
    border: 1px solid #afc0d5;
    text-align: center;
  }
`;
StyledToasts.defaultProps = {
    toastClassName: "soc-toast",
};
const CloseContainer = styled.div.withConfig({ displayName: "CloseContainer", componentId: "sc-gmtcpm" }) `
  position: absolute;
  top: 9px;
  right: 9px;

  width: 19px;
  height: 19px;

  border-radius: 50%;
  border: 1px solid ${color('surfaceIcon')};
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-color 0.2s, opacity 0.2s;

  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;
const CloseButton = ({ closeToast }) => (React.createElement(CloseContainer, { onClick: (event) => {
        event.stopPropagation();
        closeToast();
    } },
    React.createElement("svg", { width: "7", height: "7", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M2.8 3.5L.6 5.7l.7.7 2.2-2.2 2.2 2.2.7-.7-2.2-2.2 2.2-2.2-.7-.7-2.2 2.2L1.3.6l-.7.7 2.2 2.2z", fill: "#AFC0D5" }))));
StyledToasts.defaultProps = {
    closeButton: React.createElement(CloseButton, null),
};
export const Title = styled.p.withConfig({ displayName: "Title", componentId: "sc-2t91ny" }) `
  color: ${color('textBrand')};
  font-size: 13px;
  padding-right: 25px;
`;
export const ToastBody = styled.div.withConfig({ displayName: "ToastBody", componentId: "sc-1o2ft31" }) `
  margin-top: 2px;

  & a {
    font-size: 14px;
    line-height: 20px;
    margin-top: 9px;
    color: ${color('textBrand')};
  }
`;
