import { rgba } from "polished";
const colors = {
    surfaceAttention: '#F58300',
    surfaceBackground: '#0E0F0F',
    surfaceBackgroundSecondary: '#2F3133',
    surfaceBorder: '#2A2A2B',
    surfaceBrand: '#008DF2',
    surfaceCard: '#1C1D1F',
    surfaceError: '#D14E34',
    surfaceLine: 'rgba(42, 42, 43, 0.50)',
    surfaceModalTint: 'rgba(60, 60, 61, 0.85)',
    surfacePromo: '#FF6933',
    surfaceSuccess: '#1AA33D',
    textAttention: '#F58300',
    textBrand: '#008DF2',
    textError: '#D14E34',
    textMain: 'rgba(252, 252, 252, 0.85)',
    textOnBrand: '#FFFFFF',
    textPromo: '#FF6933',
    textSecondary: '#898D8F',
    textSuccess: '#1EBD46',
    surfaceBackgroundAlternate: rgba('#E6EAED', 0.5),
    surfaceDisabled: '#465A89',
    surfaceIcon: '#ADBFCD',
};
const backgrounds = {
    lightGrey: colors.surfaceBackgroundSecondary,
    checkbox: colors.surfaceBackgroundSecondary,
    input: colors.surfaceBackgroundSecondary,
    mobileButton: colors.surfaceBackgroundSecondary
};
const borders = {
    button: colors.surfaceBorder,
    checkbox: colors.surfaceBorder,
    whiteBlueButton: colors.surfaceBorder,
};
const defaultShadow = {
    S: `0px 1px 2px 0px rgba(0, 0, 0, 0.48)`,
    M: '0px 8px 32px 0px rgba(0, 0, 0, 0.24)',
    L: '0px 10px 30px rgba(0, 0, 0, 0.2)',
};
const defaultHeader = {
    bgColor: colors.surfaceBackground,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackground,
    menuButtonColor: colors.surfaceBackground,
    activeMenuButtonColor: colors.surfaceBackground,
    desktopMenuBg: colors.surfaceBackground,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: colors.surfaceBorder,
    backgroundColor: colors.surfaceBackgroundSecondary,
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackground });
export const blackTheme = {
    colors,
    backgrounds,
    borders,
    loginBackground: '#000000',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: "1224px",
    },
};
export const dashboardTheme = Object.assign(Object.assign({}, blackTheme), { header: whiteHeader });
