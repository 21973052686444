import { createSimpleApi } from "./create-simple-api";
import { request } from './request';
// const API_ENDPOINT = process.env.NEXT_PUBLIC_API_URL;
const createEndpoint = createSimpleApi(process.env.API_BASE_URL);
const socpanelApi = {
    signIn: request("login", "POST"),
    confirm: request('login/confirm', 'POST'),
    resetPasswordVariants: createEndpoint('login/resetPasswordVariants', 'POST'),
    attemptPasswordReset: createEndpoint('login/attemptResetPassword', 'POST'),
    passwordReset: createEndpoint('login/resetPassword', 'POST'),
    signUp: request("register", "POST"),
    getUser: request("user"),
    getPanels: request("panel/all"),
    createPanel: request("panel/create", "POST"),
};
export { socpanelApi };
