import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { borderRadius, color } from '@socgress/ui/themes';
import { commonMedia } from '../../common-media';
import { zord } from '../../zord';
import { mediumTextStyle } from '@socgress/ui/medium-text';
const TableContainer = styled.div.withConfig({ displayName: "TableContainer", componentId: "sc-6ef89d" }) `
  @media(max-width: 768px) {
    overflow-y: auto;
  }
`;
const Table = styled.table.withConfig({ displayName: "Table", componentId: "sc-7rpv49" }) `
  width: 100%;
  
  @media(max-width: 768px) {
    width: ${prop('width', '100%')};
  }
`;
const TableBody = styled.div.withConfig({ displayName: "TableBody", componentId: "sc-1wtctma" }) `
  & > :first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
const TableHeader = styled.div.withConfig({ displayName: "TableHeader", componentId: "sc-1tun5gc" }) `
  border-bottom: 1px solid ${color("surfaceLine")};
  background-color: ${color("surfaceBackgroundSecondary")};
  z-index: 999;
  
  border-top-left-radius: ${borderRadius()}px;
  border-top-right-radius: ${borderRadius()}px;

  ${ifProp("isSticky", css `
      top: 0;

      ${commonMedia.lessThan("tablet") `
        top: 60px; 
        `};
    `)};
  
  ${ifProp('hasHover', css `
    @media(min-width: 769px) {
      tr {
        padding: 0 22px;
      }
    }
  `)};
`;
const TableRow = styled.tr.withConfig({ displayName: "TableRow", componentId: "sc-1uua94e" }) `
  display: flex;
  align-items: stretch;
  min-height: 51px;

  padding: ${zord(0, 5)};
`;
const tableCellStyle = css `
  text-align: left;
`;
const TableHeaderCell = styled.th.withConfig({ displayName: "TableHeaderCell", componentId: "sc-1tqhyfy" }) `
  ${tableCellStyle};
  ${mediumTextStyle};
  padding: ${zord(3, 0)};

  display: flex;
  align-items: center;

  font-size: 13px;
  line-height: 120%;
  white-space: pre-wrap;
`;
const TableCell = styled.td.withConfig({ displayName: "TableCell", componentId: "sc-k9w9aq" }) `
  ${tableCellStyle};
  padding: ${zord(4, 0)};

  font-size: 14px;
  line-height: 17px;
  font-weight: 500;

  tr:not(:last-of-type) & {
    border-bottom: 1px solid ${color("surfaceLine")};
  }
`;
//#region CenterContent
const CenterContent = styled.div.withConfig({ displayName: "CenterContent", componentId: "sc-uejjsf" }) `
  display: flex;
  justify-content: center;
  flex-flow: column;

  height: 100%;
`;
//#endregion
export { TableContainer, Table, TableHeader, TableBody, TableCell, TableHeaderCell, TableRow, CenterContent };
