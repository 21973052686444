import { rgba } from 'polished';
const colors = {
    surfaceError: '#fb695e',
    textError: '#fb695e',
    surfacePromo: '#FF7438',
    textPromo: '#FF7438',
    surfaceAttention: '#ffc000',
    textAttention: '#ffc000',
    surfaceBrand: '#008DF2',
    textBrand: '#008DF2',
    textOnBrand: '#fff',
    surfaceCard: '#FFFFFF',
    surfaceSuccess: '#1EBD46',
    textSuccess: '#1EBD46',
    surfaceLine: '#EFF4FA',
    textMain: '#03061A',
    textSecondary: '#728A96',
    surfaceIcon: '#728A96',
    surfaceBorder: '#D8DAE5',
    surfaceBackground: '#FFFFFF',
    surfaceBackgroundSecondary: '#F4F7FA',
    surfaceBackgroundAlternate: rgba('#EFF4FA', 0.5),
    surfaceDisabled: rgba('#F4F7FA', 0.7),
};
export const panelStatusColors = {
    activating: '#FADB21',
    ok: '#12C78C',
    not_payed: '#FF7438',
    error: '#fb695e',
    deleted: '#fb695e',
};
const defaultShadow = {
    S: `0px 1px 2px 0px rgba(5, 16, 61, 0.08)`,
    M: '0px 8px 32px 0px rgba(0, 0, 0, 0.08)',
    L: '0px 10px 30px rgba(0, 68, 133, 0.15)',
};
const backgrounds = {
    lightGrey: '#EFF4FA',
    checkbox: '#fafcff',
    input: '#fff',
    mobileButton: 'linear-gradient(to right, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.04) 100%)'
};
const borders = {
    button: colors.surfaceBorder,
    checkbox: '#cedef2',
    whiteBlueButton: '#E1EDFA',
};
const defaultHeader = {
    bgColor: colors.surfaceBackgroundSecondary,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackgroundSecondary,
    menuButtonColor: colors.surfaceBackgroundSecondary,
    activeMenuButtonColor: colors.surfaceBackgroundSecondary,
    desktopMenuBg: colors.surfaceBackgroundSecondary,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: '#DBE7F2',
    backgroundColor: '#fff',
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackgroundSecondary });
export const defaultBotsTheme = {
    colors,
    backgrounds,
    borders,
    loginBackground: '#fff',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: '1224px',
    },
};
export const cpaLanding = Object.assign(Object.assign({}, defaultBotsTheme), { globalBackground: "#fff" });
export const dashboardTheme = Object.assign(Object.assign({}, defaultBotsTheme), { header: whiteHeader });
