import loadable from '@loadable/component';
import { reducerRegistry } from '@socgress/lib/store/reducer-registry';
import { authorizedOnly } from '@socgress/lib/constants/route-rights';
export const softwareRoutes = [
    {
        path: '/software',
        component: loadable(() => import('./pages/software-page')),
        rights: authorizedOnly,
        preloadData: ({ dispatch }) => import('features/dashboard/store').then(store => {
            reducerRegistry.register('dashboard', store.dashboardReducer);
            return dispatch(store.loadNetworks());
        }),
    },
];
