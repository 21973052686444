import { useSelector } from 'react-redux';
export const UserScope = ({ type, children }) => {
    const { user } = useSelector(state => state.auth);
    const isGuest = !user;
    const isChildrenCallable = typeof children === 'function';
    if (type === 'guest' && !isGuest && !isChildrenCallable) {
        return null;
    }
    if (type === 'user' && isGuest && !isChildrenCallable) {
        return null;
    }
    if (type === 'admin' && (isGuest || user.role !== 'admin')) {
        return null;
    }
    return isChildrenCallable ? children({ isGuest }) : children;
};
