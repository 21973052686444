import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import { USER_TYPES } from '../constants/route-rights';
import { setUser as storeSetUser } from '../store/actions/auth';
import { NOT_MANAGER } from '@socgress/lib/managers';
const initialState = {
    error: null,
    isLoading: true,
};
const Context = React.createContext(initialState);
class AuthProvider extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign({}, initialState);
        this.hasAccess = this.hasAccess.bind(this);
        this.hasManagerAccess = this.hasManagerAccess.bind(this);
        this.getHomePath = this.getHomePath.bind(this);
        this.hasAccessToLocation = this.hasAccessToLocation.bind(this);
    }
    componentDidMount() {
        const { user } = this.props;
        if (user) {
            this.checkAuthPermissions();
            this.setState({
                isLoading: false,
            });
        }
        else {
            this.fetchUser().then(() => {
                this.checkAuthPermissions();
            });
        }
    }
    componentDidUpdate(prevProps) {
        const { user } = this.props;
        const prevUserExists = !!prevProps.user;
        const currentUserExists = !!user;
        const isUserAssigned = !prevUserExists && currentUserExists;
        const isUserRemoved = !currentUserExists && prevUserExists;
        const isUserUpdated = prevUserExists && currentUserExists && user.id !== prevProps.user.id;
        if (isUserRemoved || isUserAssigned || isUserUpdated) {
            this.checkAuthPermissions();
        }
    }
    get clientType() {
        const { user } = this.props;
        if (!user) {
            return USER_TYPES.GUEST;
        }
        return USER_TYPES.USER;
    }
    get managerRole() {
        const { user } = this.props;
        if (!user) {
            return -1;
        }
        return user.manager_role;
    }
    checkAuthPermissions() {
        const { location, history } = this.props;
        if (!this.hasAccessToLocation(location.pathname)) {
            history.replace({
                pathname: this.getHomePath(),
            });
        }
    }
    fetchUser() {
        this.setState({ isLoading: true });
        return this.props
            .getUser()
            .then(user => {
            this.authSuccess(user);
        })
            .catch(error => {
            this.authFail(error);
        });
    }
    authSuccess(user) {
        this.props.setUser(user);
        this.setState({
            isLoading: false,
            error: null,
        });
    }
    authFail(error) {
        this.props.setUser(null);
        this.setState({
            isLoading: false,
            error,
        });
    }
    hasAccessToLocation(pathname) {
        let hasAccess = true;
        const matchedRoutes = matchRoutes(this.props.routes, pathname);
        if (matchedRoutes.length === 0) {
            return false;
        }
        matchedRoutes.forEach(matched => {
            const { route } = matched;
            if (Array.isArray(route.rights)) {
                hasAccess = this.hasAccess({ allowedRoles: route.rights });
            }
            if (hasAccess && Array.isArray(route.managerRights)) {
                hasAccess = this.hasManagerAccess({ allowed: route.managerRights });
            }
        });
        return hasAccess;
    }
    /*
      By default header is visible for authorized users and is hidden for guests.
      Can force state by setting route.headerVisibleFor (bool)
      headerVisibleFor is set to [] only on fastOrder, because there is custom header
      for everybody.
     */
    isLocationWithHeader(location) {
        let withHeader = null;
        matchRoutes(this.props.routes, location.pathname).forEach(matched => {
            const { route } = matched;
            /*
             If route's headerVisibleFor explicitly specified we should check current
             user type and determine that header is visible
             */
            if (route.headerVisibleFor) {
                withHeader = route.headerVisibleFor.indexOf(this.clientType) !== -1;
            }
            // no rights mean that route is available for all type of users
            // so we show the header by default
            if (withHeader === null && !route.rights) {
                withHeader = true;
            }
            if (route.rights && route.rights.some(accessRight => USER_TYPES.GUEST !== accessRight) && ((route.headerVisibleFor && route.headerVisibleFor.length >= 1) || !route.headerVisibleFor)) {
                // But if there is a route.rights, we show header only if no GUEST in
                // rights array, because it means that those route is for authorized only
                withHeader = route.rights.every(accessRight => USER_TYPES.GUEST !== accessRight);
            }
        });
        return withHeader || false;
    }
    hasAccess({ allowedRoles }) {
        return allowedRoles.includes(this.clientType);
    }
    hasManagerAccess({ allowed }) {
        return allowed.includes(this.managerRole) || (this.managerRole == NOT_MANAGER);
    }
    getHomePath() {
        const { homePaths } = this.props;
        return homePaths[this.clientType];
    }
    render() {
        const { location, children, user } = this.props;
        const { isLoading, error } = this.state;
        if (process.env.NODE_ENV === 'development' && isLoading && !user) {
            return null;
        }
        return (React.createElement(Context.Provider, { value: {
                user,
                isLoading,
                error,
                hasAccess: this.hasAccess,
                hasManagerAccess: this.hasManagerAccess,
                getRedirectPath: this.getHomePath,
                hasAccessToLocation: this.hasAccessToLocation,
                isHeaderVisible: this.isLocationWithHeader(location),
            } }, children({ isHeaderVisible: this.isLocationWithHeader(location) })));
    }
}
AuthProvider.propTypes = {
    children: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
    })).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        rights: PropTypes.arrayOf(PropTypes.oneOf([
            USER_TYPES.GUEST,
            USER_TYPES.ADMIN,
            USER_TYPES.AGENT,
            USER_TYPES.USER,
        ])),
        managerRights: PropTypes.array,
    }).isRequired,
    history: PropTypes.shape({
        replace: PropTypes.func,
    }).isRequired,
    user: PropTypes.object,
    homePaths: PropTypes.objectOf(PropTypes.string),
};
AuthProvider.defaultProps = {
    user: null,
    homePaths: {
        [USER_TYPES.GUEST]: '/login',
        [USER_TYPES.USER]: '/dashboard',
    },
};
function stateToProps(state) {
    return {
        user: state.auth.user,
    };
}
function dispatchToProps(dispatch) {
    return {
        setUser: user => dispatch(storeSetUser(user)),
    };
}
const ConnectedProvider = withRouter(connect(stateToProps, dispatchToProps)(AuthProvider));
export { ConnectedProvider as AuthProvider, Context as AuthContext };
