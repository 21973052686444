import { ROLE_ADMIN, ROLE_MODERATOR, ROLE_SUPPORT, NOT_MANAGER } from '@socgress/lib/managers';

const GUEST = "guest";
const USER = "user";
const ADMIN = "admin";
const AGENT = "agent";

export const adminOnly = [AGENT, ADMIN];
export const authorizedOnly = [AGENT, USER, ADMIN];
export const guestOnly = [GUEST];
export const allOnly = [GUEST, USER, ADMIN, AGENT]; // force auth check

export const managerAdmin = [ROLE_ADMIN];
export const managerModerator = [ROLE_MODERATOR, ROLE_ADMIN];
export const managerSupport = [ROLE_SUPPORT, ROLE_MODERATOR, ROLE_ADMIN];
export const notManager = [NOT_MANAGER];

export const USER_TYPES = {
  GUEST,
  USER,
  ADMIN,
  AGENT,
};

export const LOGOUT_REDIRECT = "logout_redirect";
