import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { useOnClickOutside } from '@socgress/lib/hooks';
function CenteredModalContent({ onOverlayClick, children }) {
    const contentRef = useRef();
    const popperPopup = document.getElementById('popper-popup');
    useOnClickOutside(contentRef, event => {
        if (popperPopup.contains(event.target)) {
            return;
        }
        event.stopImmediatePropagation();
        event.preventDefault();
        onOverlayClick(event);
    });
    return (React.createElement(CenterContentOverlay, null,
        React.createElement(Content, { ref: contentRef }, children)));
}
const CenterContentOverlay = styled.div.withConfig({ displayName: "CenterContentOverlay", componentId: "sc-1irtta3" }) `
  width: 100vw;
  min-height: 100vh;
  display: flex; // flex for h-centering

  background-color: rgba(11, 15, 41, 0.9);
  
  @media(max-width: 768px) {
    overflow-y: scroll;
    position: relative;
  }
`;
const transformDown = keyframes `
  from {
    transform: translate3d(0, -50px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-xfc7js" }) `
  margin: auto;
  max-width: 100%;
  padding: 24px;

  @media (max-width: 768px) {
    padding: 0;
    align-self: flex-start;

    transform-origin: top center;

    position: absolute;
    top: 59px;
    margin: 0;
    overflow-y: scroll;
  }

  transform: translate3d(0, -50px, 0);
  animation: ${transformDown} 0.3s ease-out forwards;
`;
CenteredModalContent.propTypes = {
    onOverlayClick: PropTypes.func.isRequired,
    disableClickOutside: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
CenteredModalContent.defaultProps = {
    disableClickOutside: null,
};
export { CenteredModalContent, CenterContentOverlay, Content };
