import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { commonMedia } from "@socgress/lib/common-media";
function PageTemplate({ sidebar, children, footer, isSidebarTabletHidden }) {
    return (React.createElement(Container, null,
        React.createElement(Content, null,
            sidebar && (React.createElement(SidebarContainer, { isTabletHidden: isSidebarTabletHidden }, sidebar)),
            React.createElement(MainSectionContainer, null, children)),
        React.createElement(FooterContainer, null, footer)));
}
PageTemplate.propTypes = {
    isSidebarTabletHidden: PropTypes.bool,
    sidebar: PropTypes.node,
    children: PropTypes.node,
    footer: PropTypes.node,
};
PageTemplate.defaultProps = {
    isSidebarTabletHidden: true,
    sidebar: null,
    children: null,
    footer: null,
};
export { PageTemplate };
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-14ofv5x" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(-60px + 100vh);
  
  max-width: 100%;

  ${commonMedia.lessThan("tablet") `
    margin-top: 60px;
    position: relative;
    overflow-x: hidden;
  `};
`;
export const contentContainerStyle = css `
  max-width: 1100px;
  width: 100%;

  padding: 0 15px;

  ${commonMedia.lessThan("tablet") `
    box-sizing: border-box;
  `};
`;
export const Content = styled.main.withConfig({ displayName: "Content", componentId: "sc-wm4ia2" }) `
  flex: 1;

  display: flex;
  justify-content: center;

  margin: 24px 0;

  ${commonMedia.lessThan("tablet") `
    flex-flow: column wrap;
    margin: 15px auto 30px;
  `};

  ${contentContainerStyle};
`;
export const SidebarContainer = styled.aside.withConfig({ displayName: "SidebarContainer", componentId: "sc-pkrzkd" }) `
  flex: 0 0 218px;
  width: 218px;
  margin-right: 30px;

  & > *:not(:first-child) {
    margin-top: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex: 0 1 auto;

    &:not(:empty) {
      margin-bottom: 15px;
    }

    ${(props) => props.isTabletHidden &&
    css `
        display: none;
      `}
  }
`;
SidebarContainer.defaultProps = {
    isTabletHidden: false,
};
export const MainSectionContainer = styled.section.withConfig({ displayName: "MainSectionContainer", componentId: "sc-ry3jsz" }) `
  flex: 1 1 auto;
  max-width: 100%;
  min-width: 0;
`;
export const FooterContainer = styled.div.withConfig({ displayName: "FooterContainer", componentId: "sc-121yegk" }) `
  flex: 0;
  max-width: 1100px;
  width: 100%;
`;
