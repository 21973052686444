import { stringify, parse } from "query-string";
import { hideModal, showModal } from "@socgress/lib/modal-system/store/store";
import { push } from "connected-react-router";

const MODAL_QUERY_KEY = "md";

const emptyModalEntry = [null, null];

const showModalType = showModal.getType();
const hideModalType = hideModal.getType();

function isMiddlewareAction(action) {
  return [showModalType, hideModalType].indexOf(action.type) !== -1;
}

function getSyncedModalName(ctor) {
  const [name] =
    syncedModals.find(([_, modalCtor]) => modalCtor === ctor) ||
    emptyModalEntry;

  return name;
}

export function getCtorByName(name) {
  const [_, ctor] =
    syncedModals.find(([modalName]) => modalName === name) || emptyModalEntry;

  return ctor;
}

function buildMd(modalName, props) {
  const propsString = encodeURIComponent(stringify(props));
  return propsString ? [modalName, propsString].join("_") : modalName;
}

export function parseMd(md) {
  if (!md) {
    return null;
  }
  const [name, query] = md.split("_");

  const props = query ? parse(decodeURIComponent(query)) : null;

  return { name, props };
}

const modalQueryMiddleware = (store) => (next) => (action) => {
  if (!isMiddlewareAction(action)) {
    return next(action);
  }

  const state = store.getState();

  const { search, pathname } = state.router.location;
  const { [MODAL_QUERY_KEY]: md, ...queryParams } = parse(search);

  const pushSearch = (params = {}) => {
    const falsyRemoved = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => Boolean(value))
    );

    if (md === falsyRemoved[MODAL_QUERY_KEY]) {
      return;
    }

    store.dispatch(
      push({
        pathname,
        query: {
          ...queryParams,
          ...falsyRemoved,
        },
      })
    );
  };

  switch (action.type) {
    case showModalType: {
      const syncedModalName = getSyncedModalName(action.payload.ctor);

      if (syncedModalName) {
        pushSearch({
          md: buildMd(syncedModalName, action.payload.props),
        });
      }

      return next(action);
    }

    case hideModalType: {
      const { stack } = state.modal;
      const [_, nextModal] = stack;

      // hideModal always remove only first modal from stack so we don't
      // need to get the state again, just check 2nd modal in the stack.
      const nextModalName =
        nextModal && getSyncedModalName(nextModal.modalCtor);

      pushSearch({
        md: nextModalName ? buildMd(nextModalName, nextModal.props) : undefined,
      });

      return next(action);
    }

    default:
      return next(action);
  }
};

export { modalQueryMiddleware, MODAL_QUERY_KEY };
