import { updateUser } from '@socgress/lib/store/actions/auth';
import { panelAuthApi } from '@socgress/lib/api';
export const addNotification = (notification) => (dispatch, getState) => {
    const { auth: { user }, } = getState();
    dispatch(updateUser({
        notifications: [notification, ...user.notifications],
    }));
};
export const removeNotification = (id) => (dispatch, getState) => {
    const { auth: { user }, } = getState();
    dispatch(updateUser({
        notifications: user.notifications.filter((value) => value.id !== id),
    }));
};
export const readNotification = (notificationId) => (dispatch) => {
    dispatch(removeNotification(notificationId));
    return panelAuthApi.readNotification({ id: notificationId });
};
