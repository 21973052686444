var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { default as getUniqueId } from "@socgress/lib/helpers/get-unique-id";
import { createAction, createReducer } from "redux-act";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
const addToast = createAction("Add toast");
export const removeToast = createAction("Remove toast");
export const toastsReducer = createReducer((on) => {
    on(addToast, (state, payload) => {
        const { toastId, toast } = payload;
        return Object.assign(Object.assign({}, state), { [toastId]: toast });
    });
    on(removeToast, (state, payload) => {
        const { toastId } = payload;
        const _a = state, _b = toastId, _ = _a[_b], nextList = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        return nextList;
    });
}, {});
export const pushToast = (toast) => (dispatch) => {
    const toastId = toast.options.toastId || getUniqueId();
    return dispatch(addToast({
        toastId,
        toast,
    }));
};
export function useToast() {
    const dispatch = useDispatch();
    const push = useCallback((content, type, options = {}) => dispatch(pushToast({
        content,
        options: Object.assign({ type }, options),
    })), [dispatch]);
    const successToast = (content) => push(content, "success");
    const infoToast = (content) => push(content, "info");
    const errorToast = (content) => push(content, "error");
    const promiseToast = (content, options) => push(content, "promise", options);
    return { successToast, infoToast, errorToast, promiseToast };
}
