import loadable from '@loadable/component';
import { authorizedOnly } from '@socgress/lib/constants/route-rights';
const DeveloperApiPage = loadable(() => import('features/developer/pages/developer-page'));
export const developerRoutes = [
    {
        path: '/developer',
        component: DeveloperApiPage,
        exact: true,
        rights: authorizedOnly,
    },
];
