import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { NightMode } from '@socgress/lib/night-mode';
import { I18nextProvider } from 'react-i18next/dist/es/I18nextProvider';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { GlobalStyle } from '@socgress/lib/global-style';
import App from 'features/app';
import { BotsGlobalStyle } from './lib/bots-global-style';
import i18next from './lib/i18n';
import { staticReducers } from 'lib/store/global';
import { configureStore } from './lib/store';
import 'ymd-react-spring-bottom-sheet/dist/style.css';
import { defaultBotsTheme } from '@socgress/ui/themes/bots-default';
import { botsNightTheme } from '@socgress/ui/themes/bots-night';
if (process.env.NODE_ENV === 'development') {
    const consoleError = console.error.bind(console);
    console.error = (...args) => {
        const excludedErrors = [/React does not recognize/, /Received `.+` for/];
        if (!args.some(item => excludedErrors.some(item2 => item2.test(item)))) {
            consoleError(...args);
        }
    };
}
const history = createBrowserHistory();
const store = configureStore(history, staticReducers);
const rootElement = document.getElementById('app');
const WrappedApplication = () => {
    const theme = isNightThemeEnabled => isNightThemeEnabled ? botsNightTheme : defaultBotsTheme;
    return (React.createElement(NightMode, null, isNightThemeEnabled => (React.createElement(ThemeProvider, { theme: theme(isNightThemeEnabled) },
        React.createElement(GlobalStyle, null),
        React.createElement(BotsGlobalStyle, null),
        React.createElement(ConnectedRouter, { history: history },
            React.createElement(App, null))))));
};
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(I18nextProvider, { i18n: i18next },
        React.createElement(WrappedApplication, null))), rootElement);
if (module.hot) {
    module.hot.accept();
}
