import { useState } from "react";
export function useHover({ mouseEnterDelayMS = 0, mouseLeaveDelayMS = 0 } = {}) {
    const [isHovering, setIsHovering] = useState(false);
    let mouseEnterTimer;
    let mouseOutTimer;
    return [
        isHovering,
        {
            onMouseOver: () => {
                clearTimeout(mouseOutTimer);
                mouseEnterTimer = setTimeout(() => setIsHovering(true), mouseEnterDelayMS);
            },
            onMouseLeave: () => {
                clearTimeout(mouseEnterTimer);
                mouseOutTimer = setTimeout(() => setIsHovering(false), mouseLeaveDelayMS);
            }
        }
    ];
}
