import { creamTheme } from './cream';
import { dashboardTheme } from './default';
import { grassyTheme } from './grassy';
import { greenTheme } from './green';
import { lightBlueTheme } from './light-blue';
import { lightBrownTheme } from './light-brown';
import { lightGreenTheme } from './light-green';
import { lightPurpleTheme } from './light-purple';
import { lightRedTheme } from './light-red';
import { lightVioletTheme } from './light-violet';
import { darkOrangeTheme } from './dark-orange';
import { blackTheme } from './black';
import { darkGreenTheme } from './dark-green';
import { darkVioletTheme } from './dark-violet';
import { mintTheme } from './mint';
import { grayRedTheme } from './gray-red';
import { lightPinkTheme } from './light-pink';
import { sunTheme } from './sun';
import { defaultBotsTheme } from '@socgress/ui/themes/bots-default';
import { botsNightTheme } from '@socgress/ui/themes/bots-night';
import { darkBlueTheme } from '@socgress/ui/themes/dark-blue';
export const availableThemes = {
    default: defaultBotsTheme,
    classic_dark: botsNightTheme,
    // dark_green: darkGreenTheme,
    // dark_orange: darkOrangeTheme,
    dark_blue: darkBlueTheme,
    dark_violet: darkVioletTheme,
    // pink: lightPinkTheme,
    // mint: mintTheme,
    gray_red: grayRedTheme,
    black: blackTheme,
    cream: creamTheme,
    // light_green: lightGreenTheme,
    light_blue: lightBlueTheme,
    // light_purple: lightPurpleTheme,
    light_red: lightRedTheme,
    // light_brown: lightBrownTheme,
    green: greenTheme,
};
export { defaultBotsTheme as defaultTheme, botsNightTheme as nightTheme, dashboardTheme, darkGreenTheme, darkOrangeTheme, darkVioletTheme, blackTheme, mintTheme, grayRedTheme, lightPinkTheme, creamTheme, lightGreenTheme, lightBlueTheme, lightPurpleTheme, lightRedTheme, greenTheme, sunTheme, lightBrownTheme, lightVioletTheme, grassyTheme, };
/* export function color(
  name: keyof Colors
): <Props, Theme extends { [key: string]: any }>(
  props: Props & {
    theme: Theme & {
      colors: Colors;
    };
  }
) => Color; */
export function color(name) {
    return ({ theme }) => {
        return theme.colors[name];
    };
}
export function borderRadius(defaultBorder = 20) {
    return () => {
        return 20;
    };
}
