import loadable from '@loadable/component';
import { authorizedOnly } from '@socgress/lib/constants/route-rights';
export const menuRoutes = [
    {
        path: '/menu',
        component: loadable(() => import('./pages/menu-page')),
        rights: authorizedOnly,
        exact: true,
    },
];
