import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
function CircleSpinner({ size, color }) {
    return (React.createElement(Wrapper, { style: {
            width: size,
            height: size,
            color,
        } },
        React.createElement(Svg, { viewBox: "0 0 50 50" },
            React.createElement(Circle, { cx: "25", cy: "25", r: "20", fill: "none" }))));
}
CircleSpinner.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
CircleSpinner.defaultProps = {
    color: "#4c9ae5",
    size: 70,
};
export { CircleSpinner };
const rotate = keyframes `
  100% {
    transform: rotate(360deg);
  }
`;
const dash = keyframes `
  0% {
    stroke-dasharray: 1px 200px;
    stroke-dashoffset: 0px;
  }

  50% {
    stroke-dasharray: 100px 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px 200px;
    stroke-dashoffset: -120px;
  }
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-fh33we" }) `
  display: block;
  overflow: hidden;
`;
const Svg = styled.svg.withConfig({ displayName: "Svg", componentId: "sc-abg5hh" }) `
  animation: ${rotate} 1.4s linear infinite;
  display: block;
`;
const Circle = styled.circle.withConfig({ displayName: "Circle", componentId: "sc-1wl3p57" }) `
  stroke: currentColor;
  stroke-linecap: round;
  animation: ${dash} 1.4s ease-in-out infinite;
  stroke-dasharray: 80px 200px;
  stroke-dashoffset: 0px;
  stroke-width: 3px;
`;
