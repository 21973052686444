var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '@socgress/ui/themes';
import { MainText } from '@socgress/ui/main-text';
const InputLabel = (_a) => {
    var { for: forProp, children: title, subtitle } = _a, props = __rest(_a, ["for", "children", "subtitle"]);
    return (React.createElement(LabelElement, Object.assign({ htmlFor: forProp }, props),
        React.createElement(Title, { as: "span" }, title),
        subtitle && React.createElement(Subtitle, null, subtitle)));
};
InputLabel.propTypes = {
    for: PropTypes.string,
    children: PropTypes.node,
    subtitle: PropTypes.string,
};
InputLabel.defaultProps = {
    for: null,
    children: null,
    subtitle: null,
};
export { InputLabel };
const LabelElement = styled.label.withConfig({ displayName: "LabelElement", componentId: "sc-15eang2" }) `
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
`;
const Title = styled(MainText).withConfig({ displayName: "Title", componentId: "sc-steene" }) `

`;
const Subtitle = styled.div.withConfig({ displayName: "Subtitle", componentId: "sc-2nc5sn" }) `
  color: ${color('textSecondary')};
  margin-top: 5px;
  line-height: 18px;
  font-weight: 500;
  font-size: 13px;
`;
