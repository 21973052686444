import loadable from '@loadable/component';
import { allOnly } from '../constants/route-rights';
import { loadDialog } from './store';
import { getLastDialogs } from './store/actions';
const cabinetUserSupportRoutes = [
    {
        path: `/${process.env.IS_SHOP ? 'shop' : 'panel'}/:id/socpanel/support/dialog`,
        component: loadable(() => import('./pages/user-chat')),
        preloadData({ dispatch }) {
            return import('./store/actions').then(store => {
                return dispatch(store.loadPanelDialog());
            });
            // return import(
            //     'store/middlewares/echo-middleware/handlers/support-dialog'
            //     ).then((eventHandler) => EchoRegistry.setHandler(
            //     ECHO_HANDLERS.SUPPORT_DIALOG,
            //     eventHandler.default,
            // ));
        },
        exact: true,
    },
];
const userSupportRoutes = [
    {
        path: '/support/dialog',
        component: loadable(() => import('./pages/user-chat')),
        headerVisibleFor: allOnly,
        preloadData({ dispatch }) {
            return import('./store/actions').then(store => {
                return dispatch(store.loadPanelDialog());
            });
            // return import(
            //     'store/middlewares/echo-middleware/handlers/support-dialog'
            //     ).then((eventHandler) => EchoRegistry.setHandler(
            //     ECHO_HANDLERS.SUPPORT_DIALOG,
            //     eventHandler.default,
            // ));
        },
        exact: true,
    },
];
const adminSupportRoutes = [
    {
        path: `/${process.env.IS_SHOP ? 'shop' : 'panel'}/:id/support/dialogs`,
        component: loadable(() => import('./pages/support-dialogs-v2')),
        exact: true,
        preloadData({ dispatch, match }) {
            return dispatch(getLastDialogs(0, match.params.id));
        },
    },
    {
        path: `/${process.env.IS_SHOP ? 'shop' : 'panel'}/:id/support/dialog/:dialog`,
        component: loadable(() => import('./pages/support-dialogs-v2')),
        preloadData({ dispatch, match }) {
            return Promise.all([
                dispatch(getLastDialogs(0, match.params.id)),
                dispatch(loadDialog(match.params.id, match.params.dialog)),
            ]);
        },
        exact: true,
    },
];
export { adminSupportRoutes, userSupportRoutes, cabinetUserSupportRoutes };
