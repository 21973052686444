import loadable from '@loadable/component';
import { authorizedOnly } from '@socgress/lib/constants/route-rights';
import { reducerRegistry } from '@socgress/lib/store/reducer-registry';
export const dashboardRoutes = [
    {
        path: '/dashboard/resume',
        component: loadable(() => import('./pages/resume-page')),
        rights: authorizedOnly,
        preloadData: ({ dispatch }) => import('./store').then(store => {
            reducerRegistry.register('dashboard', store.dashboardReducer);
            return dispatch(store.loadNetworks());
        }),
    },
    {
        path: '/dashboard',
        component: loadable(() => import('./pages/dashboard-page')),
        rights: authorizedOnly,
        preloadData: ({ dispatch }) => import('./store').then(store => {
            reducerRegistry.register('dashboard', store.dashboardReducer);
            return dispatch(store.loadDashboard());
        }),
    },
    {
        path: '/payouts/:status',
        component: loadable(() => import('./pages/payouts-page')),
        rights: authorizedOnly,
        preloadData: ({ dispatch, match }) => import('./store').then(store => {
            reducerRegistry.register('dashboard', store.dashboardReducer);
            return dispatch(store.loadMoreWithdrawals(0, match.params.status));
        }),
    },
];
