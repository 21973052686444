import { zord } from '@socgress/lib/zord';
import { BigText, MainText, MediumText, WhiteCard } from '@socgress/ui';
import { color } from '@socgress/ui/themes';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
const NetworkIcon = styled.img.withConfig({ displayName: "NetworkIcon", componentId: "sc-17nk6mv" }) `
  width: 32px;
  height: 32px;
`;
const EmptyText = styled.span.withConfig({ displayName: "EmptyText", componentId: "sc-15gj9rw" }) `
  @media(max-width: 768px) {
    white-space: pre-wrap;
  }
`;
const CardContainer = styled.div.withConfig({ displayName: "CardContainer", componentId: "sc-1ju14dx" }) `
  display: flex;

  & + & {
    margin-top: 24px;
  }

  @media(max-width: 768px) {
    flex-direction: column;

    border-radius: 20px;
    border: 1px solid ${color('surfaceBorder')};

    background: ${color('surfaceCard')};
  }
`;
const Container = styled(WhiteCard).withConfig({ displayName: "Container", componentId: "sc-xk3o1q" }) `
  padding: ${zord(5)};
  flex: 2;

  &:not(:last-child) {
    flex: 0.5;
  }

  & + & {
    margin-top: 0;
    border-left: 1px solid ${color('surfaceBorder')};
  }

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @media(max-width: 768px) {
    flex-direction: column;

    background: transparent;

    padding: 16px;

    box-shadow: none;

    & + & {
      margin-top: 0;
      border-left: none;
      border-top: 1px solid ${color('surfaceBorder')};
    }

    &:not(:last-child) {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

  }
`;
const PerformerInfoContainer = styled.div.withConfig({ displayName: "PerformerInfoContainer", componentId: "sc-yf0jcm" }) `
  display: grid;
  grid-template-columns: 1.3fr 1.3fr repeat(2, 1fr);
  align-items: flex-start;
  padding-bottom: ${zord(5)};

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${zord(4)};

    & > * {
      width: 100%;
    }
  }
`;
const InfoSectionContainer = styled.div.withConfig({ displayName: "InfoSectionContainer", componentId: "sc-13zb0t1" }) `
  display: flex;
  flex-direction: column;

  ${MediumText} {
    white-space: normal;
  }

  .green {
    color: ${color('textSuccess')};
  }

  & > :last-child {
    margin-top: ${zord(2)};
  }
`;
const CompletionsContainer = styled.div.withConfig({ displayName: "CompletionsContainer", componentId: "sc-16xp371" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 10px;
  border: 1px solid ${color('surfaceLine')};
  border-radius: 10px;
  width: 100%;
`;
const CompletionsText = styled(BigText).withConfig({ displayName: "CompletionsText", componentId: "sc-10b692w" }) `
  color: ${color('textPromo')};
`;
const CopyableContainer = styled(MainText).withConfig({ displayName: "CopyableContainer", componentId: "sc-1cjk0bp" }) `
  position: relative;
  cursor: pointer;
  width: fit-content;

  background: transparent;
  padding: 0 20px 0 0;
  border: none;

  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    content: '';
    mask: url('${require('./static/copy.svg')}') no-repeat;
    mask-size: contain;
    background-color: ${color('textMain')};
    transition: 200ms ease-in-out background-color;
  }

  &:hover {
    &:after {
      background-color: ${color('surfaceBrand')};
    }
  }
`;
const CardFooterContainer = styled.div.withConfig({ displayName: "CardFooterContainer", componentId: "sc-gw8jad" }) `
  border-top: 1px solid ${color('surfaceBorder')};

  & > div {
    padding: 14px 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const TabsContainer = styled.div.withConfig({ displayName: "TabsContainer", componentId: "sc-zdnfcs" }) `
  padding: 4px;
  border-radius: 10px;

  background: ${color('surfaceBackgroundSecondary')};

  display: flex;
  align-items: center;
  gap: 4px;
`;
const TabContainer = styled.div.withConfig({ displayName: "TabContainer", componentId: "sc-1tmaovu" }) `
  padding: 10px 18px;
  border-radius: 10px;

  cursor: pointer;

  background: ${ifProp('isActive', color('surfaceBackgroundAlternate'), 'transparent')};
`;
const EmptyContainer = styled.div.withConfig({ displayName: "EmptyContainer", componentId: "sc-h2b0yu" }) `
  margin-top: 128px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin-top: 32px;
  }
`;
const EmptyIcon = styled.img.withConfig({ displayName: "EmptyIcon", componentId: "sc-1ds236f" }) `
  width: 48px;
  height: 48px;
`;
const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-1ohxt8y" }) `
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  text-align: center;
`;
const PanelIcon = styled.img.withConfig({ displayName: "PanelIcon", componentId: "sc-1gwohdx" }) `
  width: 24px;
  height: 24px;
  object-fit: contain;
`;
const PanelContainer = styled.div.withConfig({ displayName: "PanelContainer", componentId: "sc-qgyom4" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const HeadContainer = styled.div.withConfig({ displayName: "HeadContainer", componentId: "sc-1s2dfsj" }) `
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
  align-items: flex-start;
`;
export { NetworkIcon, CardContainer, Container, PerformerInfoContainer, InfoSectionContainer, PanelIcon, PanelContainer, HeadContainer, CompletionsContainer, CompletionsText, CopyableContainer, EmptyText, CardFooterContainer, TabsContainer, TabContainer, EmptyContainer, EmptyIcon, TextContainer, };
