import { createReducer } from 'redux-act';
import { menuActions, menuInitialState } from '@socgress/lib/store/actions/app';

const reducerName = 'app';
const createActionName = name => `${reducerName}/${name}`;

/* Actions */
const START_LOADING = createActionName('START_APP_LOADING');
export const END_LOADING = createActionName('END_APP_LOADING');
export const NIGHT_THEME_TOGGLED = createActionName('NIGHT_THEME_TOGGLED');

let isNightThemeEnabled;

try {
  isNightThemeEnabled = JSON.parse(localStorage.getItem('night_theme_enabled'));
} catch (e) {}

const isDarkThemePreferred = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;


/*  Reducer */
const initialState = {
  isLoading: false,
  isNightThemeEnabled: typeof isNightThemeEnabled !== 'boolean' ? isDarkThemePreferred : isNightThemeEnabled,
  ...menuInitialState,
};

export default createReducer(on => {
  on(START_LOADING, (state) => ({
    ...state,
    isLoading: true,
  }));

  on(END_LOADING, (state) => ({
    ...state,
    isLoading: false,
  }));

  on(NIGHT_THEME_TOGGLED, state => ({
    ...state,
    isNightThemeEnabled: !state.isNightThemeEnabled,
  }));

  menuActions(on);
}, initialState);

/* Action Creators */
export const startLoading = () => ({
  type: START_LOADING,
});

export const endLoading = () => ({
  type: END_LOADING,
});
