import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NIGHT_THEME_TOGGLED } from 'lib/store/global/app';
import { usePrevious } from '@socgress/lib/hooks';
function useNightMode() {
    const dispatch = useDispatch();
    const { isNightThemeEnabled } = useSelector((state) => state.app);
    const isNightModeWasEnabled = usePrevious(isNightThemeEnabled);
    useEffect(() => {
        if (isNightModeWasEnabled === undefined || isNightModeWasEnabled === isNightThemeEnabled) {
            return;
        }
        window.localStorage.setItem('night_theme_enabled', JSON.stringify(isNightThemeEnabled));
    }, [isNightThemeEnabled]);
    const toggleNightMode = () => {
        dispatch({ type: NIGHT_THEME_TOGGLED });
    };
    return [isNightThemeEnabled, toggleNightMode];
}
export { useNightMode };
