import { request } from '@socgress/lib/api';
const supportApi = {
    /*
      General methods
     */
    getPanelMessages: request('support/messages'),
    getPanelDialog: request('support'),
    getDialog: (panelId, dialogId) => request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/${panelId}/supportDialogs/${dialogId}`)(),
    getSocpanelDialog: (dialogId) => request(`support/dialogs/${dialogId}`)(),
    getMessages: (dialogId, panelId, limit = 15, offset = 0) => request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/${panelId}/supportDialogs/${dialogId}/messages`)({
        limit,
        offset,
    }),
    getSocpanelMessages: (dialogId, limit = 15, offset = 0) => request(`support/dialogs/${dialogId}/messages`)({
        limit,
        offset,
    }),
    uploadAttachment: (file) => {
        const requestData = new FormData();
        requestData.append('file', file);
        return request('support/attachment', 'POST')(requestData);
    },
    panelSendMessage: request('support', 'POST'),
    updateDialog: request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/{panelId}/supportDialogs/{dialogId}`, 'PUT'),
    sendMessage: request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/{panelId}/supportDialogs/{dialogId}/messages`, 'POST'),
    sendSocpanelMessage: request('support/dialogs/{dialogId}', 'POST'),
    /*
      Agent methods
  
      GET /support/dialogs
      DELETE /support/messages/{message}
      PUT /support/messages/{message}
      PUT /support/dialog/{dialogId}
     */
    getDialogs: request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/{panelId}/supportDialogs`),
    getSocpanelDialogs: request(`support/dialogs`),
    deleteSocpanelMessage: request(`support/dialogs/{dialogId}/messages/{messageId}`, 'DELETE'),
    deleteMessage: request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/{panelId}/supportDialogs/{dialogId}/messages/{messageId}`, 'DELETE'),
    deleteShopMessage: request(`shops/{panelId}/supportDialogs/{dialogId}/messages/{messageId}`, 'DELETE'),
    editMessage: request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/{panelId}/supportDialogs/{dialogId}/messages/{messageId}`, 'PUT'),
    editSocpanelMessage: request(`support/dialogs/{dialogId}/messages/{messageId}`, 'PUT'),
    updateDialogOld: (dialogId, fields) => request(`support/dialogs/${dialogId}`, 'PUT')(fields),
    delegateDialog: (dialogId, comment) => request(`support/dialog/${dialogId}/delegate`, 'PUT')({ comment }),
    unDelegateDialog: (dialogId) => request(`support/dialog/${dialogId}/unDelegate`, 'PUT')(),
    reactivatePanel: (panelId) => request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/${panelId}/reactivate`, 'POST')(),
    removePanel: (panelId) => request(`${process.env.IS_SHOP ? 'shops' : 'panels'}/${panelId}`, 'DELETE')(),
    /*
      User methods
     */
    rateMessage: (messageId, rate) => request(`support/messages/${messageId}/rate`, 'POST')({
        rate,
    }),
    getFaq: () => request('support/faq')(),
};
export { supportApi };
