import PropTypes from 'prop-types';
const LocationShape = PropTypes.oneOfType([
    PropTypes.shape({
        path: PropTypes.string,
        hash: PropTypes.string,
    }),
    PropTypes.string,
]);
export const LinkShape = PropTypes.shape({
    to: LocationShape.isRequired,
    value: PropTypes.string.isRequired,
});
export const ListMenuPropTypes = {
    icon: PropTypes.shape({
        active: PropTypes.string.isRequired,
        default: PropTypes.string.isRequired,
    }).isRequired,
    to: LocationShape.isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    linkList: PropTypes.arrayOf(LinkShape),
    activeLink: LinkShape,
};
export const ListMenuDefaultProps = {
    isActive: true,
    linkList: [],
    activeLink: null,
};
