var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
class EchoRegistry {
    constructor() {
        this.handlers = {};
        this.activeSubscriptions = {};
    }
    static explodeChannelName(channelWithRoom, hideType = false) {
        // handling presence channels `SupportDialog.123` case
        const [channelName, roomId] = channelWithRoom.split(".");
        if (hideType) {
            return {
                channelName: channelWithRoom.replace('private-', ''),
            };
        }
        return {
            channelName,
            roomId: Number(roomId),
        };
    }
    static subscribeToChannel(channelWithRoom) {
        return __awaiter(this, void 0, void 0, function* () {
            const echo = yield EchoRegistry.getEcho();
            return echo.channel(channelWithRoom);
        });
    }
    static getEcho() {
        if (!EchoRegistry._echo) {
            EchoRegistry._echo = Promise.all([
                import(/* webpackChunkName: "echo-socket-io" */ "laravel-echo"),
                import(/* webpackChunkName: "echo-socket-io" */ "socket.io-client"),
            ]).then(([echoModule, socketIOModule]) => {
                const { default: LaravelEcho } = echoModule;
                const { default: io } = socketIOModule;
                return new LaravelEcho({
                    broadcaster: "socket.io",
                    client: io,
                });
            });
        }
        return EchoRegistry._echo;
    }
    _handlerExists(channelName) {
        return this._getHandler(channelName) !== undefined;
    }
    _getHandler(channelName) {
        return this.handlers[channelName];
    }
    getEcho() {
        return __awaiter(this, void 0, void 0, function* () {
            return EchoRegistry.getEcho();
        });
    }
    setHandler(channel, handler) {
        if (!this._handlerExists(channel)) {
            this.handlers[channel] = handler;
        }
    }
    subscribe(channelWithRoom, store) {
        return __awaiter(this, void 0, void 0, function* () {
            const { channelName, roomId } = EchoRegistry.explodeChannelName(channelWithRoom, true);
            if (!this._handlerExists(channelName)) {
                throw Error(`Handler for channel "${channelName}" is not found!`);
            }
            const channelHandler = this._getHandler(channelName);
            const channelSubscription = yield this.getChannelSubscription(channelWithRoom);
            channelHandler(store, roomId)(channelSubscription);
        });
    }
    leave(channelWithRoom) {
        if (this.activeSubscriptions[channelWithRoom] === undefined) {
            console.warn(`Subscription for channel ${channelWithRoom} is not found!`);
            return;
        }
        const _a = this.activeSubscriptions, 
        // eslint-disable-next-line no-unused-vars
        _b = channelWithRoom, 
        // eslint-disable-next-line no-unused-vars
        _ = _a[_b], nextActiveSubscriptions = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        this.activeSubscriptions = nextActiveSubscriptions;
        EchoRegistry.getEcho().then((echo) => echo.leave(channelWithRoom));
    }
    getChannelSubscription(channelWithRoom) {
        return __awaiter(this, void 0, void 0, function* () {
            const activeSubscription = this.activeSubscriptions[channelWithRoom];
            if (activeSubscription) {
                return activeSubscription;
            }
            const createdSubscription = yield EchoRegistry.subscribeToChannel(channelWithRoom);
            this.activeSubscriptions[channelWithRoom] = createdSubscription;
            return createdSubscription;
        });
    }
}
EchoRegistry._echo = null;
export default new EchoRegistry();
