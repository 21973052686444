import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
const NOT_MANAGER = 99;
const ROLE_SUPPORT = 0;
const ROLE_MODERATOR = 1;
const ROLE_ADMIN = 2;
export { NOT_MANAGER, ROLE_SUPPORT, ROLE_MODERATOR, ROLE_ADMIN };
const useManagerScope = (scope) => {
    // eslint-disable-next-line max-len
    const managerScope = useSelector((state) => (state.auth.user && state.auth.user.is_manager ? state.auth.user.manager_role : true));
    return (managerScope === true) || (parseInt(managerScope) >= scope);
};
export { useManagerScope };
const ManagerScope = ({ scope, children }) => {
    const isScopeEnabled = useManagerScope(scope);
    if (typeof children === 'function') {
        return children(isScopeEnabled);
    }
    return isScopeEnabled ? children : null;
};
ManagerScope.propTypes = {
    scope: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any.isRequired,
};
export { ManagerScope };
const ManagerScopeRedirect = ({ scope, children, redirect }) => {
    const isScopeEnabled = useManagerScope(scope);
    return isScopeEnabled ? children : React.createElement(Redirect, { to: redirect });
};
ManagerScopeRedirect.propTypes = {
    scope: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.node.isRequired,
    redirect: PropTypes.string,
};
ManagerScopeRedirect.defaultProps = {
    redirect: '/dashboard',
};
export { ManagerScopeRedirect };
