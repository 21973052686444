import { rgba } from "polished";
const colors = {
    surfaceAttention: '#F58300',
    surfaceBackground: '#11101F',
    surfaceBackgroundSecondary: '#353147',
    surfaceBorder: '#2D2B47',
    surfaceBrand: '#008DF2',
    surfaceCard: '#191529',
    surfaceError: '#D14E34',
    surfaceLine: 'rgba(45, 43, 71, 0.50)',
    surfaceModalTint: 'rgba(57, 51, 61, 0.85)',
    surfacePromo: '#FF6933',
    surfaceSuccess: '#1AA33D',
    textAttention: '#F58300',
    textBrand: '#008DF2',
    textError: '#D14E34',
    textMain: 'rgba(250, 252, 255, 0.85)',
    textOnBrand: '#FFFFFF',
    textPromo: '#FF6933',
    textSecondary: '#9189A3',
    textSuccess: '#1EBD46',
    surfaceBackgroundAlternate: rgba('#E6EAED', 0.5),
    surfaceDisabled: '#465A89',
    surfaceIcon: '#ADBFCD',
};
const backgrounds = {
    lightGrey: colors.surfaceBackgroundSecondary,
    checkbox: colors.surfaceBackgroundSecondary,
    input: colors.surfaceBackgroundSecondary,
    mobileButton: colors.surfaceBackgroundSecondary
};
const borders = {
    button: colors.surfaceBorder,
    checkbox: colors.surfaceBorder,
    whiteBlueButton: colors.surfaceBorder,
};
const defaultShadow = {
    S: `0px 1px 2px 0px rgba(13, 7, 19, 0.72)`,
    M: '0px 8px 32px 0px rgba(14, 5, 18, 0.40)',
    L: '0px 10px 30px rgba(0, 0, 0, 0.2)',
};
const defaultHeader = {
    bgColor: colors.surfaceBackground,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackground,
    menuButtonColor: colors.surfaceBackground,
    activeMenuButtonColor: colors.surfaceBackground,
    desktopMenuBg: colors.surfaceBackground,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: colors.surfaceBorder,
    backgroundColor: colors.surfaceBackgroundSecondary,
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackground });
export const darkVioletTheme = {
    colors,
    backgrounds,
    borders,
    loginBackground: 'linear-gradient(180deg, #3A0067 0%, #1D0F33 100%)',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: "1224px",
    },
};
export const dashboardTheme = Object.assign(Object.assign({}, darkVioletTheme), { header: whiteHeader });
