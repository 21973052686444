import { modalsReducer } from '@socgress/lib/modal-system/store';
import { toastsReducer } from '@socgress/lib/toasts/store';
import supportReducer from '@socgress/lib/support/store';
import auth from './auth';
import app from './app';
export const staticReducers = {
    app,
    auth,
    modal: modalsReducer,
    toasts: toastsReducer,
    supportReducer,
};
