import { createAction, createReducer } from 'redux-act';
export const setUser = createAction('Set user');
export const updateUser = createAction('Update user');
export const notificationRead = createAction('Update notification status');
const initialState = {
    user: null,
    isLoading: true,
    isFailed: false,
    error: null,
};
export default createReducer(on => {
    on(setUser, (state, payload) => {
        if (payload && payload.is_new) {
            delete payload.is_new;
        }
        return Object.assign(Object.assign({}, state), { user: payload });
    });
    on(notificationRead, (state, payload) => (Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { notifications: state.user.notifications.filter(notification => notification.id !== payload) }) })));
    on(updateUser, (state, payload) => (Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), payload) })));
}, initialState);
