import loadable from '@loadable/component';
import { guestOnly } from '@socgress/lib/constants/route-rights';
export const authRoutes = [
    {
        path: '/login',
        component: loadable(() => import('./pages/login')),
        rights: guestOnly,
    },
    {
        path: '/register',
        component: loadable(() => import('./pages/register')),
        rights: guestOnly,
    },
];
