/**
 * Используется для темлейт-эндпоинтов вроде /users/{id}/panels
 * Заолняет id из параметров
 * @param endpointTemplate
 * @param payload
 * @param ignoreEmpty
 */
const fillEndpointTemplate = (endpointTemplate, payload = {}, ignoreEmpty = false) => endpointTemplate.replace(/{([a-zA-Z]+)}/g, (match, key) => {
    const replaceValue = payload[key];
    if (replaceValue === undefined && !ignoreEmpty) {
        throw Error(`Can't find key for API template ${key} in payload`);
    }
    if (replaceValue === undefined && ignoreEmpty) {
        return `{${key}}`;
    }
    return replaceValue;
});
export { fillEndpointTemplate };
